import React from "react";
import "react-circular-progressbar/dist/styles.css";

const Player = ({ singleTeam }) => {
  if (singleTeam.length === 0) {
    return <div>No Team Found</div>;
  }
  const {
    teamName,
    actualposition,
    league,
    city,
    coach,
    season,
    moreInformation,
    injuries,
    match,
    yellow_cards,
    seasonresume,
    avator,
    red_cards,
  } = singleTeam;
  const { matches_dates, matches_results, matches_weather, matches } = match;

  return (
    <div>
      <section className="clubwrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="detail-img">
                <img
                  className="rounded-circle"
                  width="70"
                  height="70"
                  src={avator}
                  style={{ objectFit: "cover" }}
                  alt="topanalyst"
                />
                <h5>{teamName}</h5>
              </div>
            </div>

            <div className="col-md-3">
              <div className="blueshade">
                <div className="clubdetail teamdetail player-detail">
                  <div className="field-styled">
                    <h6>
                      <span>Play style: </span> <span>{actualposition}</span>
                    </h6>
                  </div>

                  <div className="field-styled">
                    <h6>
                      <span>Coach: </span> <span>{coach}</span>
                    </h6>
                  </div>

                  <div className="field-styled">
                    <h6>
                      <span>League: </span> <span>{league}</span>
                    </h6>
                  </div>

                  <div className="field-styled">
                    <h6>
                      <span>City: </span> <span>{city}</span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="blueshade">
                <div className="clubdetail teamdetail player-detail">
                  <div className="field-styled">
                    <h6>
                      <span>Season: </span> <span>{season}</span>
                    </h6>
                  </div>

                  <div className="field-styled">
                    <h6>
                      <span>Matches: </span>
                      <span>{matches || "not found"}</span>
                    </h6>
                  </div>

                  <div className="field-styled">
                    <h6>
                      <span>Results: </span> <span>{matches_results}</span>
                    </h6>
                  </div>

                  <div className="field-styled">
                    <h6>
                      <span>Matches Dates: </span> <span>{matches_dates}</span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="blueshade">
                <div className="clubdetail teamdetail player-detail">
                  <div className="field-styled">
                    <h6>
                      <span>Matches Weather: </span>
                      <span>{matches_weather}</span>
                    </h6>
                  </div>

                  <div className="field-styled">
                    <h6>
                      <span>Yellow Cards: </span> <span>{yellow_cards}</span>
                    </h6>
                  </div>

                  <div className="field-styled">
                    <h6>
                      <span>Red Cards: </span> <span>{red_cards}</span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="blueshade">
                <div className="clubdetail teamdetail player-detail">
                  <div className="field-styled">
                    <h6>
                      <span>Season resume: </span> <span>{seasonresume}</span>
                    </h6>
                  </div>

                  <div className="field-styled">
                    <h6>
                      <span>More Information: </span>
                      <span>{moreInformation}</span>
                    </h6>
                  </div>

                  <div className="field-styled">
                    <h6>
                      <span>Injuries situation: </span> <span>{injuries}</span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Player;
