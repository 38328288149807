import React from "react";
import { NavLink } from "react-router-dom";

const ProfileLeftSideNav = () => {
  return (
    <>
      <div className="profilenav mt-md-5">
        <NavLink exact activeClassName="active" to="/edit-profile">
          Edit profile
        </NavLink>
        <NavLink activeClassName="active" to="/change-password">
          Password
        </NavLink>
        <NavLink activeClassName="active" to="/end-subscription">
          Subscription
        </NavLink>
      </div>
    </>
  );
};
export default ProfileLeftSideNav;
