import React from "react";
import moment from "moment";

const PlayerCard = (props) => {
  const { PlayersList, handleCardClick } = props;
  const separator = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <>
      {PlayersList.length > 0 ? (
        PlayersList.map((player, index) => {
          return (
            <div
              key={index}
              onClick={() => handleCardClick(player._id)}
              style={{ cursor: "pointer" }}
              className="col-xl-3 col-lg-4 col-md-6 col-12"
            >
              <div className="blueshade">
                <div className="card-header">
                  <h4 className="flex-grow-1">
                    {player.surName}
                    {/* {player.teamName.length > 0 
                      ? player.teamName.map((club) => {
                          return `${player.surName}`;
                        }) : ""
                      } */}
                    
                  </h4>
                  <img
                    className="mx-auto rounded-circle"
                    style={{ objectFit: "cover", objectPosition: "center" }}
                    src={player.avator}
                    alt="topanalyst"
                    width="100"
                    height="100"
                  />
                </div>
                <div className="teamdetail">
                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Name: </span> <span>{player.name}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Surname: </span> <span>{player.surName}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Nationality: </span>
                      <span>{player.nationality}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>League: </span> <span>{player.league}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Height: </span>
                      <span>{separator(player.height || 0)}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Weight: </span>
                      <span>{separator(player.weight) || 0}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Agency: </span> <span>{player.agency}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Date of Birth: </span>
                      <span>{moment(player.DOB).format("DD-MM-YYYY")}</span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="col-md-12 text-center fullheightWrapper no-record-page">
          <h2>No record found</h2>
        </div>
      )}
    </>
  );
}

export default PlayerCard;
