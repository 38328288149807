import React from "react";

const PlayerCard = (props) => {
  const { TeamsArray, handleCardClick, nonEdit } = props;

  return (
    <>
      {TeamsArray.length > 0 ? (
        TeamsArray.map((team, index) => {
          return (
            <div
              key={index}
              onClick={nonEdit ? () => {} : () => handleCardClick(team._id)}
              style={nonEdit ? {} : { cursor: "pointer" }}
              className="col-md-3"
            >
              <div className="blueshade team-analysis">
                <div className="card-header">
                  <h4 className="flex-grow-1">{team.teamName}</h4>
                  <img
                    className="d-block mx-auto rounded-circle"
                    style={{ objectFit: "cover" }}
                    src={team.avator}
                    alt="topanalyst"
                    width="100"
                    height="100"
                  />
                </div>
                <div className="teamdetail">
                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Play Style: </span>
                      <span>{team.actualposition}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>City: </span> <span>{team.city}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>League: </span> <span>{team.league}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Season: </span> <span>{team.season}</span>
                    </h6>
                  </div>

                  <div className="penalty-cards">
                    {/* Field */}
                    <div>
                      {team.yellow_cards && (
                        <h6>
                          <span className="card-ry yellow-cards"></span>
                          <span>{team.yellow_cards}</span>
                        </h6>
                      )}
                    </div>

                    {/* Field */}
                    <div>
                      <h6>
                        <span className="card-ry red-cards"></span>
                        <span>{team.red_cards}</span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="col-md-12  text-center mt-150">
          <h4 className="text-grey"> No data found in record</h4>
        </div>
      )}
    </>
  );
}

export default PlayerCard;
