import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header";
import ProfileLeftSideNav from "../../components/ProfileLeftSideNav";
import * as Action from "../../action";
import { selectCurrentUser } from "../User/user.select";
import { logout } from "../User/user.slice";
import { useHistory } from "react-router-dom";
const ChangePass = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const currentUser = useSelector(selectCurrentUser);
  const handelsubscriptionClick = async () => {
    if (!currentUser.subscription.active) {
      return window.alert(
        "Currently you are not using any subscription package"
      );
    }

    const confirmation = window.confirm(
      "Once you End Subscription You will be unable to use any Feature"
    );
    if (confirmation) {
      const id = currentUser._id;
      const SCI = currentUser.subscription.SCI;
      const action = await dispatch(Action.delluserSubscription({ id, SCI }));
      if (action.payload.success) {
        await dispatch(logout());
        history.push("/");
      }
    }
  };
  return (
    <>
      <Header />
      <section className="editprofilesec">
        <div className="container">
          <div className="row">
            <div className="col-md-3 br-2">
              <ProfileLeftSideNav />
            </div>

            <div className="col-md-9 text-center">
              <h2 className="text-green mt-5">Message for end subscription</h2>
              <div className="mt-5">
                <button
                  className="btn btnprimary"
                  onClick={handelsubscriptionClick}
                >
                  End Subscription
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ChangePass;
