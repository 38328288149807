import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../index";

export const monthallySubscription = createAsyncThunk(
  "schote/monthallySubscription/get",
  async ({ id, data }, store) => {
    return await axios.post(`/schote/monthallyubscribe/${id}`, data);
  }
);

export const lifeTimeSubscription = createAsyncThunk(
  "schote/lifeTimeSubscription/get",
  async ({ id, data }, store) => {
    return await axios.post(`/schote/lifetimesubscribe/${id}`, data);
  }
);

export const validateCoupon = createAsyncThunk(
  "schote/validateCoupon",
  async (payload, store) => {
    return await axios.post("/schote/validateCoupon", payload);
  }
);
