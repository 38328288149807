import React from "react";
import "react-circular-progressbar/dist/styles.css";
import graph2 from "../../assets/images/crossicon.png";
import graph3 from "../../assets/images/graphicon.png";
import graph4 from "../../assets/images/searchicon.png";
import graph1 from "../../assets/images/greencheck.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

const PlayerFooter = (props) => {
  const { singleTeam, TeamskillsAvg } = props;

  if (singleTeam.length === 0 || TeamskillsAvg.length === 0)
    return <div>Wait</div>;

  const array = [];

  for (let i = 0; i < TeamskillsAvg.length; i++) {
    const ob = TeamskillsAvg[i];
    if (ob._id === "poss") array[0] = ob.avg;
    if (ob._id === "non_poss") array[1] = ob.avg;
    if (ob._id === "men") array[2] = ob.avg;
  }

  const Possession = [
    "inizio azione",
    "sviluppo azione",
    "finalizzazione azione",
    "gioco per vie centrali",
    "gioco per vie esterne",
    "effettività azione",
    "fluidità di manovra",
    "calci piazzati a favore",
  ];

  const Non_possession = [
    "altezza pressing",
    "pressing individuale",
    "pressing collettivo",
    "transizione positiva",
    "verticalità",
    "transizione negativa",
    "aggressività",
    "calci piazzati contro",
  ];

  const playermentalSkillsCustomFields = [
    "lavoro di squadra",
    "concentrazione",
    "forza mentale",
    "fisicità",
    "resistenza",
    "gioco aereo",
  ];

  return (
    <section className="graphwrapper mt-0 pt-0">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4">
            <div className="blueshade play-style-graph">
              <p>Play Style: {singleTeam.actualposition}</p>
              <img
                className="img-fluid "
                src={
                  singleTeam.actualposition &&
                  require(`../../assets/images/team-screen/${singleTeam.actualposition}.jpg`)
                    .default
                }
                alt="topanalyst"
              />
            </div>
          </div>

          <div className="col-md-8">
            <div className="row progress-row-justify detail-circle-row">
              <div className="col-xl-3 col-lg-4 col-md-4">
                <div className="blueshade mb-4 circlechart">
                  <div className="card-header">
                    <h5>qualità in possesso</h5>
                  </div>
                  <div className="circle-progressbar">
                    <CircularProgressbar
                      value={10 * Number(array[0].toFixed(2))}
                      text={array[0].toFixed(2)}
                      strokeWidth={15}
                      styles={buildStyles({
                        textColor: "#19f495",
                        pathColor: "#19f495",
                        trailColor: "#0a3c43",
                      })}
                    />
                  </div>
                </div>

                <div className="blueshade">
                  <div className="clubdetail new-club-detail">
                    <h6>Qualità In Possesso</h6>
                    {singleTeam.skills[0].value.map((el, index) => {
                      return (
                        <h6 key={index}>
                          {`${Possession[index]}`}
                          <span className="float-right">{el}</span>
                        </h6>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-4">
                <div className="blueshade mb-4 circlechart">
                  <div className="card-header">
                    <h5>Qualità In Non-Possesso e Transizione</h5>
                  </div>
                  <div className="circle-progressbar">
                    <CircularProgressbar
                      value={10 * Number(array[1].toFixed(2))}
                      text={array[1].toFixed(2)}
                      strokeWidth={15}
                      styles={buildStyles({
                        textColor: "#19f495",
                        pathColor: "#19f495",
                        trailColor: "#0a3c43",
                      })}
                    />
                  </div>
                </div>

                <div className="blueshade">
                  <div className="clubdetail new-club-detail">
                    <h6>Qualità In NON-Possesso e Transizione</h6>
                    {singleTeam.skills[1].value.map((el, index) => {
                      return (
                        <h6 key={index}>
                          {`${Non_possession[index]}`}
                          <span className="float-right">{el}</span>
                        </h6>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-4">
                <div className="blueshade mb-4 circlechart">
                  <div className="card-header">
                    <h5>qualità fisiche e mentali</h5>
                  </div>
                  <div className="circle-progressbar">
                    <CircularProgressbar
                      value={10 * Number(array[2].toFixed(2))}
                      text={array[2].toFixed(2)}
                      strokeWidth={15}
                      styles={buildStyles({
                        textColor: "#19f495",
                        pathColor: "#19f495",
                        trailColor: "#0a3c43",
                      })}
                    />
                  </div>
                </div>

                <div className="blueshade">
                  <div className="clubdetail new-club-detail">
                    <h6>Qualità Fisiche e Mentali</h6>
                    {singleTeam.skills[2].value.map((el, index) => {
                      return (
                        <h6 key={index}>
                          {`${playermentalSkillsCustomFields[index]}`}
                          <span className="float-right">{el}</span>
                        </h6>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 my-4">
            <div className="card-header description-field-header">
              <h5>Play Style</h5>
            </div>
            <div className="description-field">
              <textarea
                className="detail-textarea form-control bg-darkblue"
                rows={6}
                readOnly
              >
                {singleTeam.playstyledetail}
              </textarea>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="blueshade">
              <div className="card-header description-field-header">
                <h5>Strengths</h5>
                <img className="img-fluid" src={graph1} alt="topanalyst" />
              </div>
              <div className="description-field">
                <textarea
                  className="detail-textarea form-control bg-darkblue"
                  rows={4}
                  readOnly
                >
                  {singleTeam.strength}
                </textarea>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="blueshade">
              <div className="card-header description-field-header">
                <h5>Weaknesses</h5>
                <img className="img-fluid" src={graph2} alt="topanalyst" />
              </div>
              <div className="description-field">
                <textarea
                  className="detail-textarea form-control bg-darkblue"
                  rows={4}
                  readOnly
                >
                  {singleTeam.weakness}
                </textarea>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="blueshade">
              <div className="card-header description-field-header">
                <h5>Vote</h5>
                <img className="img-fluid" src={graph3} alt="topanalyst" />
              </div>
              <div className="description-field">
                <textarea
                  className="detail-textarea form-control bg-darkblue"
                  rows={4}
                  readOnly
                >
                  {singleTeam.vote}
                </textarea>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="blueshade">
              <div className="card-header description-field-header">
                <h5>Scout/Analyst</h5>
                <img className="img-fluid" src={graph4} alt="topanalyst" />
              </div>
              <div className="description-field">
                <textarea
                  className="detail-textarea form-control bg-darkblue"
                  rows={4}
                  readOnly
                >
                  {singleTeam.scouteanalyst}
                </textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PlayerFooter;
