import React, { useEffect } from "react";
import * as Action from "../../action";
import { setInitialValues } from "./team.slice";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import SingleTeam from "../../components/Team/SingleTeam";
import { selectSingleTeam, selectskillsAvg } from "./selector";
import { TeamsInitialValuesBuilder } from "../../utils/teamArrayBuilder";

const PlayerEditView = () => {
  const dispatch = useDispatch();
  const singleTeam = useSelector(selectSingleTeam);
  const TeamskillsAvg = useSelector(selectskillsAvg);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    dispatch(Action.getSpecificTeam(id));
    dispatch(Action.GetSkillsAvg(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handelEditClick = async () => {
    const {
      teamName,
      actualposition,
      league,
      city,
      season,
      moreInformation,
      injuries,
      match,
      coach,
      yellow_cards,
      seasonresume,
      avator,
      red_cards,
      playstyledetail,
    } = singleTeam;
    const { matches_dates, matches_results, matches_weather, matches } = match;

    const newObject = TeamsInitialValuesBuilder(singleTeam);

    const myObject = {
      matches,
      matches_dates,
      matches_results,
      matches_weather,
      teamName,
      actualposition,
      league,
      city,
      season,
      moreInformation,
      injuries,
      coach,
      yellow_cards,
      seasonresume,
      pic: avator,
      red_cards,
      playstyledetail,
      ...newObject,
    };

    await dispatch(setInitialValues(myObject));
    history.push(`/team/edit=true&id=${singleTeam._id}/edit`);
  };

  const handelDeleteClick = async () => {
    const confirmBox = window.confirm(
      "All playera of this team will be deleted atomatically"
    );
    if (confirmBox) {
      await dispatch(Action.deleteTeam(id));
      history.push(`/teams`);
    }
  };

  return (
    <SingleTeam
      handelEditClick={handelEditClick}
      handelDeleteClick={handelDeleteClick}
      singleTeam={singleTeam}
      TeamskillsAvg={TeamskillsAvg}
    />
  );
};

export default PlayerEditView;
