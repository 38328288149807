import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { Formik } from "formik";
import { SingupFormValidation } from "../../validation";
import Field from "../Field";
const SignUp = (props) => {
  const { handleFormSubmit, initialValues } = props;

  return (
    <>
      <section>
        <div className="container-fluid login-container">
          <div className="row align-items-center">
            <div className="col-md-6 text-center">
              <img className="img-fluid authlogo" src={logo} alt="" />
            </div>
            <div className="col-md-6 login-form-2 d-md-flex align-items-center">
              <div className="w-100">
                <h1>Signup</h1>
                <Formik
                  validationSchema={SingupFormValidation}
                  onSubmit={handleFormSubmit}
                  initialValues={initialValues}
                >
                  {(formik) => {
                    const { handleSubmit, errors, touched, isSubmitting } =
                      formik;
                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <p className="my-4">
                            Already have an account? <Link to="/">Login</Link>
                          </p>
                        </div>

                        <fieldset className="new-file-input login-fields">
                          <legend className="file-input__label">
                            First Name
                          </legend>
                          <Field
                            name="firstName"
                            type="text"
                            className="form-control file-input__input"
                            errors={errors}
                            touched={touched}
                          />
                        </fieldset>

                        <fieldset className="new-file-input login-fields">
                          <legend className="file-input__label">
                            Last Name
                          </legend>
                          <Field
                            name="lastName"
                            type="text"
                            className="form-control file-input__input"
                            errors={errors}
                            touched={touched}
                          />
                        </fieldset>

                        <fieldset className="new-file-input login-fields">
                          <legend className="file-input__label">
                            Enter Email
                          </legend>
                          <Field
                            name="email"
                            type="email"
                            className="form-control file-input__input"
                            errors={errors}
                            touched={touched}
                          />
                        </fieldset>

                        <fieldset className="new-file-input login-fields">
                          <legend className="file-input__label">
                            Enter Password
                          </legend>
                          <Field
                            name="password"
                            type="password"
                            className="form-control file-input__input"
                            errors={errors}
                            touched={touched}
                          />
                        </fieldset>

                        <div className="form-group">
                          <button
                            type="submit"
                            className="btnprimary"
                            disabled={isSubmitting}
                          >
                            Signup
                          </button>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default SignUp;
