import { Formik, Field } from "formik";
import React from "react";
import "react-circular-progressbar/dist/styles.css";
import { addNewTeamPersonalData } from "../../validation";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import graph1 from "../../assets/images/greencheck.png";
import graph2 from "../../assets/images/crossicon.png";
import graph3 from "../../assets/images/graphicon.png";
import graph4 from "../../assets/images/searchicon.png";
import newPlayerIcon from "../../assets/images/icons/new-player-icon.png";


import CustomField from "../Field";
import uploadPicture from "../../utils/uploadPic";

const Player = (props) => {
  const { handleFormSubmit, initialValues } = props;

  const newInitalvalues = {
    poss0: "",
    poss1: "",
    poss2: "",
    poss3: "",
    poss4: "",
    poss5: "",
    poss6: "",
    poss7: "",

    non_poss0: "",
    non_poss1: "",
    non_poss2: "",
    non_poss3: "",
    non_poss4: "",
    non_poss5: "",
    non_poss6: "",
    non_poss7: "",

    men0: "",
    men1: "",
    men2: "",
    men3: "",
    men4: "",
    men5: "",

    strength: "",
    weakness: "",
    vote: "",
    scouteanalyst: "",

    ...initialValues,
  };

  const playerInfromationInputCustomFields = [
    { name: "coach", type: "text", placeholder: "Enter Coach" },
    { name: "teamName", type: "text", placeholder: "Enter Club's Name" },
    { name: "league", type: "text", placeholder: "Enter League" },
    { name: "city", type: "text", placeholder: "Enter City" },
    { name: "season", type: "text", placeholder: "Enter Season" },
    { name: "matches", type: "text", placeholder: "Enter Matches" },
    { name: "matches_results", type: "text", placeholder: "Enter Results" },
    { name: "matches_dates", type: "text", placeholder: "Enter Matches Dates" },
    {
      name: "matches_weather",
      type: "text",
      placeholder: "Enter Matches Weather",
    },
    { name: "yellow_cards", type: "text", placeholder: "Enter Yellow Cards" },
    { name: "red_cards", type: "text", placeholder: "Enter Red Cards" },
    { name: "seasonresume", type: "text", placeholder: "Enter Season Resume" },
    {
      name: "moreInformation",
      type: "text",
      placeholder: "Enter More Information",
    },
    { name: "injuries", type: "text", placeholder: "Enter Injuries Situation" },
  ];

  const votesGraphData = [
    {
      name: "strength",
      heading: "strength",
      type: "textarea",
      placeholder: "Enter here...",
      hidelable: false,
    },
    {
      name: "weakness",
      heading: "weakness",
      type: "textarea",
      placeholder: "Enter here...",
      hidelable: false,
    },
    {
      name: "vote",
      heading: "vote",
      type: "textarea",
      placeholder: "Enter here...",
      hidelable: false,
    },

    {
      name: "scouteanalyst",
      heading: "scout analyst",
      type: "textarea",
      placeholder: "Enter here...",
      hidelable: false,
    },
  ];

  const Possession = [
    {
      name: "poss0",
      type: "number",
      placeholder: "inizio azione",
      hidelable: false,
    },
    {
      name: "poss1",
      type: "number",
      placeholder: "sviluppo azione",
      hidelable: false,
    },

    {
      name: "poss2",
      type: "number",
      placeholder: "finalizzazione azione",
      hidelable: false,
    },
    {
      name: "poss3",
      type: "number",
      placeholder: "gioco per vie centrali",
      hidelable: false,
    },

    {
      name: "poss4",
      type: "number",
      placeholder: "gioco per vie esterne",
      hidelable: false,
    },

    {
      name: "poss5",
      type: "number",
      placeholder: "effettività azione",
      hidelable: false,
    },

    {
      name: "poss6",
      type: "number",
      placeholder: "fluidità di manovra",
      hidelable: false,
    },

    {
      name: "poss7",
      type: "number",
      placeholder: "calci piazzati a favore",
      hidelable: false,
    },
  ];

  const Non_possession = [
    {
      name: "non_poss0",
      type: "number",
      placeholder: "altezza pressing",
      hidelable: false,
    },
    {
      name: "non_poss1",
      type: "number",
      placeholder: "pressing individuale",
      hidelable: false,
    },
    {
      name: "non_poss2",
      type: "number",
      placeholder: "pressing collettivo",
      hidelable: false,
    },
    {
      name: "non_poss3",
      type: "number",
      placeholder: "transizione positiva",
      hidelable: false,
    },
    {
      name: "non_poss4",
      type: "number",
      placeholder: "verticalità",
      hidelable: false,
    },
    {
      name: "non_poss5",
      type: "number",
      placeholder: "transizione negativa",
      hidelable: false,
    },
    {
      name: "non_poss6",
      type: "number",
      placeholder: "aggressività",
      hidelable: false,
    },
    {
      name: "non_poss7",
      type: "number",
      placeholder: "calci piazzati contro",
      hidelable: false,
    },
  ];

  const playermentalSkillsCustomFields = [
    {
      name: "men0",
      type: "number",
      placeholder: "lavoro di squadra",
      hidelable: false,
    },
    {
      name: "men1",
      type: "number",
      placeholder: "concentrazione",
      hidelable: false,
    },
    {
      name: "men2",
      type: "number",
      placeholder: "forza mentale",
      hidelable: false,
    },
    {
      name: "men3",
      type: "number",
      placeholder: "fisicità",
      hidelable: false,
    },
    {
      name: "men4",
      type: "number",
      placeholder: "resistenza",
      hidelable: false,
    },
    {
      name: "men5",
      type: "number",
      placeholder: "gioco aereo",
      hidelable: false,
    },
  ];

  return (
    <div>
      <section className="clubwrapper">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="blueshade d-flex minh-100">
                <Formik
                  validationSchema={addNewTeamPersonalData}
                  onSubmit={handleFormSubmit}
                  initialValues={newInitalvalues}
                  enableReinitialize={true}
                >
                  {(formik) => {
                    const {
                      handleSubmit,
                      values,
                      errors,
                      touched,
                      handleChange,
                      isSubmitting,
                    } = formik;

                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="analysis-header">
                            <div className="choose-file-top">
                              <div className="custom-file">
                                <div
                                  className="select_file"
                                  for="validatedCustomFile"
                                >
                                  <img
                                    className="d-block"
                                    src={values.pic || initialValues.pic}
                                    alt="topanalyst"
                                    width="75"
                                    height="75"
                                    style={{
                                      borderRadius: "50%",
                                      objectFit: "cover",
                                    }}
                                    for="validatedCustomFile"
                                  />
                                  <div className="file-select-btn">
                                    <span>Nessun file selezionato</span>
                                    <label className="custom-file-upload">
                                      <input
                                        name="pic"
                                        type="file"
                                        id="validatedCustomFile"
                                        onChange={async (event) => {
                                          const file = event.target.files[0];
                                          let fromdata = new FormData();
                                          fromdata.append("image", file);
                                          const useLink = await uploadPicture(
                                            fromdata
                                          );
                                          formik.setFieldValue(
                                            "pic",
                                            useLink.imageUrl
                                          );
                                        }}
                                      />
                                      Select File
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="add-new-player">
                              <img src={newPlayerIcon} alt="" />
                              <h2>Add New Team</h2>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <fieldset className="new-file-input">
                              <legend className="file-input__label">
                                Select Position
                              </legend>
                              <Field
                                name="actualposition"
                                component="select"
                                className="form-control file-input__input"
                              >
                                <option value=""></option>
                                {[
                                  "1-3-4-1-2",
                                  "1-3-4-2-1",
                                  "1-3-4-3",
                                  "1-3-5-2",
                                  "1-4-1-3-2",
                                  "1-4-2-3-1",
                                  "1-4-2-4",
                                  "1-4-3-1-2",
                                  "1-4-3-2-1",
                                  "1-4-3-3",
                                  "1-4-4-2",
                                  "1-4-5-1",
                                  "1-5-2-1-2",
                                  "1-5-2-2-1",
                                  "1-5-2-3",
                                  "1-5-3-2",
                                  "1-5-4-1",
                                ].map((value, index) => {
                                  return (
                                    <option
                                      disabled={
                                        initialValues.actualposition &&
                                        value === "goalkeeper"
                                      }
                                      key={index}
                                      value={value}
                                    >
                                      {value}
                                    </option>
                                  );
                                })}
                              </Field>
                              {errors["actualposition"] &&
                                touched["actualposition"] && (
                                  <span className="text-danger">
                                    {errors["actualposition"]}
                                  </span>
                                )}
                            </fieldset>
                          </div>

                          {playerInfromationInputCustomFields.map(
                            ({ name, type, placeholder }, index) => {
                              return (
                                <div className="col-md-3" key={index}>
                                  <fieldset className="new-file-input">
                                    <legend className="file-input__label">
                                      {placeholder}
                                    </legend>
                                    <CustomField
                                      name={name}
                                      type={type}
                                      className="file-input__input"
                                      errors={errors}
                                      touched={touched}
                                    />
                                  </fieldset>
                                </div>
                              );
                            }
                          )}
                        </div>

                        <div className="graphwrapper">
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col-md-4">
                                <div className="blueshade play-style-graph">
                                  <p>Play style: {values.actualposition}</p>
                                  <img
                                    className="img-fluid"
                                    src={values.actualposition && require(`../../assets/images/team-screen/${values.actualposition}.jpg`).default}
                                    alt={
                                      !values.actualposition
                                        ? "Select play style to place picture"
                                        : "alt"
                                    }
                                  />
                                </div>
                              </div>

                              <div className="col-md-8">
                                <div className="row mb-3 progress-row-justify">
                                  <div className="col-xl-3 col-lg-4 col-md-4">
                                    <div className="blueshade mb-4 circlechart">
                                      <div className="card-header">
                                        <h5>qualità in possesso</h5>
                                      </div>
                                      <div className="circle-progressbar">
                                        <CircularProgressbar
                                          value="0"
                                          text="0"
                                          strokeWidth={15}
                                          styles={buildStyles({
                                            textColor: "#19f495",
                                            pathColor: "#19f495",
                                            trailColor: "#0a3c43",
                                          })}
                                        />
                                      </div>
                                    </div>

                                    <div className="blueshade d-flex">
                                      <div className="clubdetail new-club-detail">
                                        <h6>qualità in possesso</h6>
                                        {Possession.map(
                                          ({
                                            name,
                                            type,
                                            placeholder,
                                            hidelable,
                                          }, index) => {
                                            return (
                                              <fieldset className="new-file-input" key={index}>
                                                <legend className="file-input__label">
                                                  {placeholder}
                                                </legend>
                                                <CustomField
                                                  name={name}
                                                  type={type}
                                                  hidelable={hidelable}
                                                  className="file-input__input"
                                                  errors={errors}
                                                  touched={touched}
                                                />
                                              </fieldset>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-xl-3 col-lg-4 col-md-4">
                                    <div className="blueshade mb-4 circlechart">
                                      <div className="card-header">
                                        <h5>
                                          qualità in non-possesso e transizione
                                        </h5>
                                      </div>
                                      <div className="circle-progressbar">
                                        <CircularProgressbar
                                          value="0"
                                          text="0"
                                          strokeWidth={15}
                                          styles={buildStyles({
                                            textColor: "#19f495",
                                            pathColor: "#19f495",
                                            trailColor: "#0a3c43",
                                          })}
                                        />
                                      </div>
                                    </div>

                                    <div className="blueshade d-flex">
                                      <div className="clubdetail new-club-detail">
                                        <h6>
                                          qualità in non-posesso e transizione
                                        </h6>
                                        {Non_possession.map(
                                          ({
                                            name,
                                            type,
                                            placeholder,
                                            hidelable,
                                          }, index) => {
                                            return (
                                              <fieldset className="new-file-input" key={index}>
                                                <legend className="file-input__label">
                                                  {placeholder}
                                                </legend>
                                                <CustomField
                                                  name={name}
                                                  type={type}
                                                  hidelable={hidelable}
                                                  className="file-input__input"
                                                  errors={errors}
                                                  touched={touched}
                                                />
                                              </fieldset>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-xl-3 col-lg-4 col-md-4">
                                    <div className="blueshade mb-4 circlechart">
                                      <div className="card-header">
                                        <h5>qualità fisiche e mentali</h5>
                                      </div>
                                      <div className="circle-progressbar">
                                        <CircularProgressbar
                                          value="0"
                                          text="0"
                                          strokeWidth={15}
                                          styles={buildStyles({
                                            textColor: "#19f495",
                                            pathColor: "#19f495",
                                            trailColor: "#0a3c43",
                                          })}
                                        />
                                      </div>
                                    </div>

                                    <div className="blueshade d-flex">
                                      <div className="clubdetail new-club-detail">
                                        <h6>qualità fisiche e mentali</h6>
                                        {playermentalSkillsCustomFields.map(
                                          ({
                                            name,
                                            type,
                                            placeholder,
                                            hidelable,
                                          }, index) => {
                                            return (
                                              <fieldset className="new-file-input" key={index}>
                                                <legend className="file-input__label">
                                                  {placeholder}
                                                </legend>
                                                <CustomField
                                                  name={name}
                                                  type={type}
                                                  hidelable={hidelable}
                                                  className="file-input__input"
                                                  errors={errors}
                                                  touched={touched}
                                                />
                                              </fieldset>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12 mt-3 mb-4">
                                <div className="card-header description-field-header">
                                  <h5>Play Style</h5>
                                </div>
                                <div className="description-field">
                                  <CustomField
                                    name="playstyledetail"
                                    type="textarea"
                                    hidelable={false}
                                    value={values.playstyledetail}
                                    handleChange={handleChange}
                                    placeholder="Enter description..."
                                    className="form-control"
                                    errors={errors}
                                    row="5"
                                    touched={touched}
                                  />
                                </div>
                              </div>

                              {votesGraphData.map((obj, index) => {
                                return (
                                  <div className="col-lg-4 col-md-6" key={index}>
                                    <div className="blueshade">
                                      <div className="card-header description-field-header">
                                        <h5>{obj.heading}</h5>
                                        <img
                                          className="img-fluid d-block"
                                          src={
                                            index === 0
                                              ? graph1
                                              : index === 1
                                              ? graph2
                                              : index === 2
                                              ? graph3
                                              : index === 3
                                              ? graph4
                                              : index === 4
                                              ? graph4
                                              : ""
                                          }
                                          alt=""
                                        />
                                      </div>
                                      <div className="description-field">
                                        <CustomField
                                          name={obj.name}
                                          type={obj.type}
                                          hidelable={obj.hidelable}
                                          value={values[obj.name]}
                                          handleChange={handleChange}
                                          placeholder={obj.placeholder}
                                          className="form-control"
                                          errors={errors}
                                          touched={touched}
                                          row="4"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 text-right">
                          <button
                            className="btn btnprimary mb-3 mt-3"
                            disabled={isSubmitting}
                            type="submit"
                          >
                            Save
                          </button>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Player;
