import React from "react";
import * as Action from "../../action";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ResetPass from "../../components/ResetPass";

const ResetPassCom = () => {
  const prams = useParams();
  const dispatch = useDispatch();

  const handleFormSubmit = async (formValues, { resetForm, setSubmitting }) => {
    const newObject = { token: prams.token, formdata: formValues };
    const action = await dispatch(Action.restPassword(newObject));
    if (action.payload.success) {
      resetForm();
    }
    setSubmitting(false);
  };

  return <ResetPass handleFormSubmit={handleFormSubmit} />;
};

export default ResetPassCom;
