import { createSlice } from "@reduxjs/toolkit";
import { validateCoupon } from "./packages.action";

const initialState = {
  couponCode: {},
};

const packagesSlice = createSlice({
  name: "packagesSlice",
  initialState,
  reducers: {},

  extraReducers: {
    [validateCoupon.fulfilled.toString()]: (state, action) => {
      if (action.payload.success) {
        state.couponCode = action.payload;
      }
    },
  },
});

export default packagesSlice.reducer;
