import React, { useEffect } from "react";
import * as Action from "../../action";
import { useHistory } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { selectTeamsArray } from "./selector";
import "react-circular-progressbar/dist/styles.css";
import TeamCard from "../../components/Team/TeamCard";
import { useDispatch, useSelector } from "react-redux";

const PlayerComp = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const TeamsArray = useSelector(selectTeamsArray);

  const handelClick = () => {
    history.push("/team/addNew");
  };

  const handleClick = async (index) => {
    history.push(`/team/${index}`);
  };

  useEffect(() => {
    dispatch(Action.getAllteam());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="body">
      <section className="clubwrapper mt-0">
        <Header />
        <div className="container-fluid">
          <div className="row mt-5 mb-5">
            <div className="col-md-12 text-right">
              <button className="btn btnprimary" onClick={handelClick}>
                Add New Team
              </button>
            </div>
          </div>

          <div className="row" style={{ marginBottom: "100px" }}>
            <TeamCard handleCardClick={handleClick} TeamsArray={TeamsArray} />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default PlayerComp;
