import React from "react";
import { Field } from "formik";

const renderTextField = ({
  name,
  type,
  placeholder,
  errors,
  touched,
  handleChange,
  className,
  row,
  hidelable,
  onBlur,
  value,
  ...rest
}) => {
  return (
    <>
      {!hidelable && <label htmlFor={placeholder}></label>}
      {type === "textarea" ? (
        <textarea
          className="form-control"
          name={name}
          type={type}
          value={value}
          onBlur={onBlur}
          onChange={handleChange}
          placeholder={placeholder}
          {...rest}
          rows={row || "2"}
        />
      ) : (
        <Field
          name={name}
          type={type}
          className={className}
          placeholder={placeholder}
          {...rest}
        />
      )}

      {errors[name] && touched[name] ? (
        <span className="text-danger">{errors[name]}</span>
      ) : null}
    </>
  );
};

export default renderTextField;
