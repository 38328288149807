import React from "react";
import * as Action from "../../action";
import { selectCurrentUser } from "./user.select";
import { useDispatch, useSelector } from "react-redux";
import EditProfile from "../../components/CustomerEditProfile";

const EditProfileCom = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);

  const handleFormSubmit = async (formValues, { resetForm, setSubmitting }) => {
    const newFormValues = { ...formValues, _id: currentUser._id };
    const action = await dispatch(Action.updateUserData(newFormValues));

    if (action.payload.success) {
      resetForm();
    }
    setSubmitting(false);
  };

  const handleProfileChange = (event) => {
    const file = event.target.files[0];
    var bodyFormData = new FormData();
    bodyFormData.append("image", file);
    dispatch(Action.UploadProfileAction(bodyFormData));
  };

  return (
    <EditProfile
      currentUser={currentUser}
      handleProfileChange={handleProfileChange}
      handleFormSubmit={handleFormSubmit}
    />
  );
};

export default EditProfileCom;
