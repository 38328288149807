import React from "react";
import Header from "../Header";
import ProfileLeftSideNav from "../ProfileLeftSideNav";
import dummyuser from "../../assets/images/dummyuser.png";

import { Formik } from "formik";
import { EditProfileFormValidation } from "../../validation";
import Field from "../Field";

const CustomerEditProfile = (props) => {
  const { handleFormSubmit, handleProfileChange, currentUser } = props;

  const [initialValues, setInitialValues] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  React.useEffect(() => {
    setInitialValues({
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      email: currentUser.email,
    });
  }, [currentUser]);

  return (
    <>
      <Header />

      <section className="editprofilesec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="profiler">
                <h2>{`${currentUser.firstName} ${currentUser.lastName}`}</h2>
              </div>
            </div>

            <div className="col-md-3 br-2">
              <ProfileLeftSideNav />
            </div>

            <div className="col-md-9">
              <div className="paymentform">
                <label className="custom-file" htmlFor="pic">
                  <img
                    style={{ objectFit: "cover", borderRadius: "50%" }}
                    width="80"
                    height="80"
                    src={currentUser.avator || dummyuser}
                    alt="top"
                  />
                  <input onChange={handleProfileChange} type="file" id="pic" />
                </label>

                <Formik
                  validationSchema={EditProfileFormValidation}
                  onSubmit={handleFormSubmit}
                  initialValues={initialValues}
                  enableReinitialize={true}
                >
                  {(formik) => {
                    const { handleSubmit, errors, touched, isSubmitting } =
                      formik;

                    return (
                      <form onSubmit={handleSubmit} className="inputcolor">
                        <fieldset className="new-file-input login-fields">
                          <legend className="file-input__label">
                            firstName
                          </legend>
                          <Field
                            name="firstName"
                            id="fname"
                            type="text"
                            className="form-control file-input__input"
                            errors={errors}
                            touched={touched}
                          />
                        </fieldset>

                        <fieldset className="new-file-input login-fields">
                          <legend className="file-input__label">
                            lastName
                          </legend>
                          <Field
                            name="lastName"
                            id="fname"
                            type="text"
                            className="form-control file-input__input"
                            errors={errors}
                            touched={touched}
                          />
                        </fieldset>

                        <fieldset className="new-file-input login-fields">
                          <legend className="file-input__label">Email</legend>
                          <Field
                            name="email"
                            type="email"
                            className="form-control file-input__input"
                            errors={errors}
                            touched={touched}
                          />
                        </fieldset>

                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="btn btnprimary ml-auto d-block"
                        >
                          Save Profile
                        </button>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default CustomerEditProfile;
