import React from "react";
import LoginForm from "../../components/LogIn";
import * as Action from "../../action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectFresUser } from "./user.select";

const User = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const fresUser = useSelector(selectFresUser);

  const handleFormSubmit = async (formValues, { resetForm, setSubmitting }) => {
    const action = await dispatch(Action.loginAction(formValues));
    if (action.payload.success) {
      resetForm();
      history.push("/teams");
    }
    setSubmitting(false);
  };

  return <LoginForm fresUser={fresUser} handleFormSubmit={handleFormSubmit} />;
};

export default User;
