import React, { useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import Teamrview from "./Teamrview";
import TeamFooter from "./TeamFooter";

const SinglePlayer = (props) => {
  const [index] = useState(0);
  const { singleTeam, TeamskillsAvg, handelEditClick, handelDeleteClick } =
    props;

  return (
    <>
      <Header />
      <div className="body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-md-right text-center mb-md-0 mb-4 mt-5">
              <button
                className="btn btnprimary mr-3"
                onClick={() => handelEditClick()}
              >
                Edit Team
              </button>
              <button
                className="btn btndanger "
                onClick={() => handelDeleteClick()}
              >
                Delete
              </button>
            </div>

            <div className="col-md-12">
              {index === 0 ? (
                <div>
                  <Teamrview singleTeam={singleTeam} />
                </div>
              ) : (
                <React.Fragment>
                  <div className="">
                    <div className="col-md-12 text-right">
                      <button className="btn btnprimary mt-3 mb-3">
                        Save Changes
                      </button>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
            <TeamFooter TeamskillsAvg={TeamskillsAvg} singleTeam={singleTeam} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SinglePlayer;
