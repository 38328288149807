import * as Action from "../../action";

import { useDispatch, useSelector } from "react-redux";

import Footer from "../Footer";
import Header from "../Header";
import React from "react";
import { promoCodeSelector } from "../../container/packges/packags.selector";
import toastBuilder from "../../utils/toastBuilder";

const Packages = (props) => {
  const {
    handleMonthallySubscription,
    handleLifetimeSubscription,
    setCouponInput,
    couponInput,
  } = props;
  const dispatch = useDispatch();

  const promoCode = useSelector(promoCodeSelector);

  const validateCouponCode = async () => {
    const result = await dispatch(
      Action.validateCoupon({ couponCode: couponInput })
    );
    if (result.payload.success) {
      toastBuilder("success", "Promo Code Successfully Applied");
    } else {
      toastBuilder("error", result.payload.data.msg);
    }
  };

  return (
    <main className="body">
      <Header />

      {/* <section className="pricing-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h1>Packages</h1>
              {!promoCode.success ? (
                <p data-toggle="modal" data-target="#exampleModal">
                  Add Promo Code to get discount.
                </p>
              ) : (
                <p>Discount Applied!</p>
              )}
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8 col-md12">
              <div className="card-deck mb-3 text-center">
                <div className="card mb-5">
                  <div className="card-header">
                    <h4 className="my-0">MONTHLY</h4>
                  </div>
                  <div className="card-body">
                    <h2 className="card-title pricing-card-title">
                      29€ <small className="text-muted">/ month</small>
                    </h2>
                    <ul className="list-unstyled my-4">
                      <li>2 GB of storage</li>
                      <li>Email support</li>
                      <li>Help center access</li>
                    </ul>
                    <button
                      type="button"
                      className="btn btnprimary"
                      onClick={() => handleMonthallySubscription()}
                    >
                      Get started
                    </button>
                  </div>
                </div>

                <div className="card mb-5">
                  <div className="card-header">
                    <h4 className="my-0">LIFETIME</h4>
                  </div>
                  <div className="card-body">
                    <h2 className="card-title pricing-card-title">
                      {promoCode.success
                        ? (299 - (promoCode.discount * 299) / 100).toFixed(2) +
                          "€"
                        : "299€"}
                      <small className="text-muted"> / lifetime</small>
                    </h2>
                    <ul className="list-unstyled my-4">
                      <li>10 GB of storage</li>
                      <li>Priority email support</li>
                      <li>Help center access</li>
                    </ul>
                    <button
                      type="button"
                      className="btn btnprimary"
                      onClick={() => handleLifetimeSubscription()}
                    >
                      Get started
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <div>
        <div className="stripe-style">
          <stripe-pricing-table
            pricing-table-id="prctbl_1M7zLNKMaQRpGvpBBVXvpq7U"
            publishable-key="pk_live_51JUwutKMaQRpGvpBonAbQj5IyL12PWHDzRuXZfyI2Ajeo4pebhhnwfNUsDHu0iP4YWUMlJZTDeL4kt4QNEYxKmyi00tdEugQZL"
          ></stripe-pricing-table>
        </div>
      </div>

      <Footer />

      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Promo Code
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <i class="fa-solid fa-xmark"></i>
                </span>
              </button>
            </div>
            <div className="modal-body">
              <span className="d-block">Add Promo Code:</span>
              <input
                type="text"
                className="form-control mt-2 mb-4"
                onChange={(e) => setCouponInput(e.target.value)}
              />
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModal"
                onClick={() => validateCouponCode()}
              >
                Apply Code
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default Packages;
