// {{url}}/schote/login/

import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../index";

export const getCurrnetUser = createAsyncThunk(
  "user/getCurrnetUser/get",
  async (formdata, store) => {
    return await axios.get("/schote/getCurrnetUser");
  }
);

export const loginAction = createAsyncThunk(
  "user/login/post",
  async (formdata, store) => {
    return await axios.post("/schote/login", formdata);
  }
);

export const singnupAction = createAsyncThunk(
  "user/singup/post",
  async (formdata, store) => {
    return await axios.post("/schote/singup", formdata);
  }
);

export const ForgetPassAction = createAsyncThunk(
  "user/forgetPassword/post",
  async (formdata, store) => {
    return await axios.post("/schote/forgetPassword", formdata);
  }
);

export const UploadProfileAction = createAsyncThunk(
  "user/uploadProfle/post",
  async (formdata, store) => {
    return await axios.post("/schote/uploadProfle", formdata);
  }
);

export const updateUserData = createAsyncThunk(
  "user/updateUserData/post",
  async (formdata, store) => {
    return await axios.patch("/schote/updateUserData", formdata);
  }
);

export const updatePassword = createAsyncThunk(
  "user/updatePassword/post",
  async (formdata, store) => {
    return await axios.patch("/schote/updatePassword", formdata);
  }
);

export const restPassword = createAsyncThunk(
  "user/restPassword/post",
  async (data, store) => {
    const { token, formdata } = data;
    return await axios.patch("/schote/restPassword/" + token, formdata);
  }
);

export const delluserSubscription = createAsyncThunk(
  "user/restPassword/post",
  async (data, store) => {
    return await axios.post("/schote/deleteSubscription", data);
  }
);
