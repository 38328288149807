import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as Action from "../../action";

const Conf = () => {
  const dispatch = useDispatch();
  const [subsc, setsubsc] = useState({
    status: false,
    message: "choose a subscription plan to explore more",
  });

  useEffect(() => {
    const fetchData = async () => {
      const result = await dispatch(Action.getCurrnetUser());
      if (result.payload) {
        const { user } = result.payload;

        if (user.subscription.active) {
          setsubsc({
            status: true,
            message: "Welcome to TopAnalyst by Emiscouting",
          });
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="body welcome-page">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="mb-5">{subsc.message}</h1>
            {subsc.status && (
              <Link className="btn btnprimary" to="/players">
                Go to Home
              </Link>
            )}
            {!subsc.status && (
              <Link to="/packages">Choose a subscriptions plan</Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Conf;
