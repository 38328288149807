import React from "react";
import Routes from "./routes";
import "dotenv/config";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { selectloader } from "./container/loader/loader.selector";

const App = () => {
  const loader = useSelector(selectloader);

  return (
    <div>
      <ToastContainer position="top-right" />
      <Routes />
      <div className="overlay" style={{ display: loader ? "block" : "none" }}>
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default App;
