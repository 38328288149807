import React, { useEffect, useState } from "react";
import * as Action from "../../action";
import { useHistory } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { selectPlayerArray } from "./selector";
import "react-circular-progressbar/dist/styles.css";
import { useDispatch, useSelector } from "react-redux";
import PlayerCard from "../../components/Player/PlayerCard";

const PlayerComp = () => {
  const dispatch = useDispatch();
  const PlayersList = useSelector(selectPlayerArray);
  const [goalkeper, setgoalkeper] = useState(false);
  const history = useHistory();

  const handelClick = () => {
    history.push("/player/addNew");
  };

  const handleClick = (id) => {
    dispatch(Action.getSpecifiPlayer(id));
    history.push(`/player/${id}`);
  };

  const arrayofPath = history.location.pathname.split("/");

  useEffect(() => {
    dispatch(Action.getAllPlayer());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (arrayofPath.includes("goalkepers")) {
        const goalkepre12 = PlayersList.filter((object) => {
          return (
            object.position === "POR-Moderno" ||
            object.position === "POR-Classico"
          );
        });
        setgoalkeper(goalkepre12);
      } else {
        setgoalkeper(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PlayersList]);

  return (
    <div className="body">
      <section className="clubwrapper mt-0">
        <Header />
        <div className="container-fluid">
          <div className="row my-5">
            <div className="col-md-12 text-right">
              {!goalkeper && (
                <button className="btn btnprimary" onClick={handelClick}>
                  Add New Player
                </button>
              )}
            </div>
          </div>
          <div className="row mb-4">
            <PlayerCard
              handleCardClick={handleClick}
              PlayersList={!!goalkeper ? goalkeper : PlayersList}
            />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default PlayerComp;
