import React from "react";
import graph1 from "../../assets/images/greencheck.png";
import graph2 from "../../assets/images/crossicon.png";
import graph3 from "../../assets/images/graphicon.png";

const Possession = [
  "inizio azione",
  "sviluppo azione",
  "finalizzazione azione",
  "gioco per vie centrali",
  "gioco per vie esterne",
  "effettività azione",
  "fluidità di manovra",
  "calci piazzati a favore",
];

const Non_possession = [
  "altezza pressing",
  "pressing individuale",
  "pressing collettivo",
  "transizione positiva",
  "verticalità",
  "transizione negativa",
  "aggressività",
  "calci piazzati contro",
];

const playermentalSkillsCustomFields = [
  "lavoro di squadra",
  "concentrazione",
  "forza mentale",
  "fisicità",
  "resistenza",
  "gioco aereo",
];

const mainName = [
  "qualità in possesso",
  "qualità in non-possesso e transizione",
  "qualità fisiche e mentali",
];

const Home = (props) => {
  const { twoTeamValues, teams } = props;

  if (twoTeamValues.length === 0) {
    return (
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-md-6">
            <h3 className="mt-150 mb-300 no-player-heading">
              No team selected
            </h3>
          </div>
        </div>
      </div>
    );
  }

  let player1;
  let player2;

  if (twoTeamValues[1]._id === teams.team1) {
    player1 = twoTeamValues[1];
    player2 = twoTeamValues[0];
  } else {
    player2 = twoTeamValues[1];
    player1 = twoTeamValues[0];
  }

  return (
    <div className="compare-detail">
      <section className="clubwrapper">
        <div className="container-fluid">
          <div className="row mt-5">
            <div className="col-md-3">
              <div className="blueshade">
                <div className="card-header">
                  <h4 className="flex-grow-1">{player1.teamName}</h4>
                  <img
                    className="mx-auto rounded-circle"
                    style={{ objectFit: "cover", objectPosition: "center" }}
                    src={player1.avator}
                    alt="topanalyst"
                    width="100"
                    height="100"
                  />
                </div>

                <div className="clubdetail teamdetail">
                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Play style: </span>
                      <span> {player1.actualposition} </span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Coach: </span> <span>{player1.coach}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>League: </span>
                      <span>{player1.league}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>City: </span> <span>{player1.city}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="penalty-cards my-3">
                    <div>
                      <h6>
                        <span className="card-ry yellow-cards"></span>
                        <span>{player1.yellow_cards}</span>
                      </h6>
                    </div>
                    <div>
                      <h6>
                        <span className="card-ry red-cards"></span>
                        <span>{player1.red_cards}</span>
                      </h6>
                    </div>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Matches: </span>
                      <span>{player1.match.matches}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Results: </span>
                      <span>{player1.match.matches_results}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Dates: </span>
                      <span>{player1.match.matches_dates}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Weather: </span>
                      <span>{player1.match.matches_weather}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Scout: </span>
                      <span>{player1.scouteanalyst}</span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="blueshade play-style-graph">
                <p>Play Style: {player1.actualposition}</p>
                <img
                  className="img-fluid"
                  src={
                    player2.actualposition &&
                    require(`../../assets/images/team-screen/${player1.actualposition}.jpg`)
                      .default
                  }
                  alt="topanalyst"
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="blueshade play-style-graph">
                <p>Play Style: {player2.actualposition}</p>
                <img
                  className="img-fluid"
                  src={
                    player1.actualposition &&
                    require(`../../assets/images/team-screen/${player2.actualposition}.jpg`)
                      .default
                  }
                  alt="topanalyst"
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="blueshade">
                <div className="card-header">
                  <h4 className="flex-grow-1">{player2.teamName}</h4>
                  <img
                    className="mx-auto rounded-circle"
                    style={{ objectFit: "cover", objectPosition: "center" }}
                    src={player2.avator}
                    alt="topanalyst"
                    width="100"
                    height="100"
                  />
                </div>

                <div className="clubdetail teamdetail">
                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Play style: </span>
                      <span> {player2.actualposition} </span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Coach: </span>
                      <span>{player2.coach}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>League: </span>
                      <span>{player2.league}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>City: </span>
                      <span>{player2.city}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="penalty-cards my-3">
                    <div>
                      <h6>
                        <span className="card-ry yellow-cards"></span>
                        <span>{player2.yellow_cards}</span>
                      </h6>
                    </div>
                    <div>
                      <h6>
                        <span className="card-ry red-cards"></span>
                        <span>{player2.red_cards}</span>
                      </h6>
                    </div>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Matches: </span>
                      <span>{player2.match.matches}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Results: </span>
                      <span>{player2.match.matches_results}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Dates: </span>
                      <span>{player2.match.matches_dates}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Weather: </span>
                      <span>{player2.match.matches_weather}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Scout: </span>
                      <span>{player2.scouteanalyst}</span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="row justify-content-start">
                <div className="col-md-6">
                  <div className="blueshade">
                    <div className="card-header description-field-header">
                      <h5 className="text-center">Strengths</h5>
                      <img
                        className="img-fluid"
                        src={graph1}
                        alt="topanalyst"
                      />
                    </div>
                    <div className="description-field">
                      <textarea
                        className="detail-textarea form-control bg-darkblue"
                        rows={4}
                        readOnly
                      >
                        {player1.strength}
                      </textarea>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="blueshade">
                    <div className="card-header description-field-header">
                      <h5>Weaknesses</h5>
                      <img
                        className="img-fluid"
                        src={graph2}
                        alt="topanalyst"
                      />
                    </div>
                    <div className="description-field">
                      <textarea
                        className="detail-textarea form-control bg-darkblue"
                        rows={4}
                        readOnly
                      >
                        {player1.weakness}
                      </textarea>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="blueshade">
                    <div className="card-header description-field-header">
                      <h5>Vote</h5>
                      <img
                        className="img-fluid"
                        src={graph3}
                        alt="topanalyst"
                      />
                    </div>
                    <div className="description-field">
                      <textarea
                        className="detail-textarea form-control bg-darkblue"
                        rows={2}
                        readOnly
                      >
                        {player1.vote}
                      </textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="row justify-content-end">
                <div className="col-md-6">
                  <div className="blueshade">
                    <div className="card-header description-field-header">
                      <h5>Strengths</h5>
                      <img
                        className="img-fluid"
                        src={graph1}
                        alt="topanalyst"
                      />
                    </div>
                    <div className="description-field">
                      <textarea
                        className="detail-textarea form-control bg-darkblue"
                        rows={4}
                        readOnly
                      >
                        {player2.strength}
                      </textarea>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="blueshade">
                    <div className="card-header description-field-header">
                      <h5>Weaknesses</h5>
                      <img
                        className="img-fluid"
                        src={graph2}
                        alt="topanalyst"
                      />
                    </div>
                    <div className="description-field">
                      <textarea
                        className="detail-textarea form-control bg-darkblue"
                        rows={4}
                        readOnly
                      >
                        {player2.weakness}
                      </textarea>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="blueshade">
                    <div className="card-header description-field-header">
                      <h5>Vote</h5>
                      <img
                        className="img-fluid"
                        src={graph3}
                        alt="topanalyst"
                      />
                    </div>
                    <div className="description-field">
                      <textarea
                        className="detail-textarea form-control bg-darkblue"
                        rows={2}
                        readOnly
                      >
                        {player2.vote}
                      </textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="comparisonwrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="comparisontable">
                    {player1.skills.map((obj, outerindex) => {
                      return (
                        <div key={outerindex} className="progress-bar-compare">
                          <h4>{mainName[outerindex]}</h4>

                          <div>
                            {obj.value.map((el, index) => {
                              return (
                                <div key={index}>
                                  <div className="progress-bar-row">
                                    <p>
                                      {outerindex === 0 && Possession[index]}
                                      {outerindex === 1 &&
                                        Non_possession[index]}
                                      {outerindex === 2 &&
                                        playermentalSkillsCustomFields[index]}
                                    </p>

                                    <div className="inputrange">
                                      <div className="progress">
                                        <div
                                          className="progress-bar"
                                          role="progressbar"
                                          style={{
                                            width: Number(el) * 10 + "%",
                                          }}
                                          aria-valuenow="25"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                        >
                                          {el}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="comparisontable">
                    {player2.skills.map((obj, outerindex) => {
                      return (
                        <div key={outerindex} className="progress-bar-compare">
                          <h4>{mainName[outerindex]}</h4>
                          <div className="">
                            {obj.value.map((el, index) => {
                              return (
                                <div key={index}>
                                  <div className="progress-bar-row">
                                    <p className="">
                                      {outerindex === 0 && Possession[index]}
                                      {outerindex === 1 &&
                                        Non_possession[index]}
                                      {outerindex === 2 &&
                                        playermentalSkillsCustomFields[index]}
                                    </p>

                                    <div className="inputrange">
                                      <div className="progress">
                                        <div
                                          className="progress-bar"
                                          role="progressbar"
                                          style={{
                                            width: Number(el) * 10 + "%",
                                          }}
                                          aria-valuenow="25"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                        >
                                          {el}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Home;
