import React, { useEffect } from "react";
import * as Action from "../../action";
import { setInitialValues } from "./slice";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { selectSinglePlayer, selectskillsAvg } from "./selector";
import { PlayerInitialValuesBuilder } from "../../utils/Skills";
import SinglePlayerComp from "../../components/Player/SinglePlayer";

const PlayerEditView = () => {
  const dispatch = useDispatch();
  const singlePlayer = useSelector(selectSinglePlayer);
  const PlayerskillsAvg = useSelector(selectskillsAvg);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    dispatch(Action.getSpecifiPlayer(id));
    dispatch(Action.GetPlayerSkillsAvg(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handelEditClick = async () => {
    const {
      name,
      surName,
      DOB,
      nationality,
      league,
      height,
      weight,
      agency,
      playstyledetail,
      match,
      position,
      foot,
      career,
      avator,
      injuries,
      moreInformation,
    } = singlePlayer;
    const { matchDates, matchName, matchResults, matchWeather } = match;

    var curr2 = new Date(DOB);
    var date2 = curr2.toISOString().substr(0, 10);
    const newObject = PlayerInitialValuesBuilder(singlePlayer);

    const myObject = {
      matchName,
      matchResults,
      matchWeather,
      name,
      surName,
      nationality,
      league,
      height,
      weight,
      agency,
      match,
      position,
      foot,
      career,
      playstyledetail,
      injuries,
      moreInformation,
      ...newObject,
      team: singlePlayer.teamid,
      DOB: date2,
      matchDates,
      pic: avator,
    };

    await dispatch(setInitialValues(myObject));
    history.push(`/player/edit=true&id=${singlePlayer._id}/edit`);
  };

  const handelDeleteClick = async () => {
    const confirmBox = window.confirm(
      "Do you really want to delete this player"
    );
    if (confirmBox) {
      await dispatch(Action.deletePlayer(id));
      history.push(`/players`);
    }
  };

  return (
    <SinglePlayerComp
      handelEditClick={handelEditClick}
      PlayerskillsAvg={PlayerskillsAvg}
      handelDeleteClick={handelDeleteClick}
      SinglePlayer={singlePlayer}
    />
  );
};

export default PlayerEditView;
