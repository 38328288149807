import React, { useEffect, useState } from "react";
import * as Action from "../../action";
import { setPicLink } from "./team.slice";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import uploadPicture from "../../utils/uploadPic";
import { selectinitialValues } from "./selector";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import dummyuser from "../../assets/images/dummyuser.png";
import { setInitialValuesClear } from "../Team/team.slice";
import AddNewTeamComp from "../../components/Team/AddNewTeam";
import { TeamsArrayBulder } from "../../utils/teamArrayBuilder";

const AddNewPlayer = () => {
  const prams = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [EditMode, setEditMode] = useState(false);
  const initialValues = useSelector(selectinitialValues);

  useEffect(() => {
    if (!!prams.id) {
      const id = prams.id.split("&");

      if (id[0] === "edit=true") {
        const usetId = id[1].split("=")[1];
        setEditMode(usetId);
      }
    } else {
      dispatch(setInitialValuesClear({}));
      dispatch(setPicLink(dummyuser));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitFormhandler = async (
    FormValues,
    { resetForm, setSubmitting }
  ) => {
    const match = {
      matches: FormValues.matches,
      matches_results: FormValues.matches_results,
      matches_dates: FormValues.matches_dates,
      matches_weather: FormValues.matches_weather,
    };

    const newFormData = { ...FormValues, match };
    const updateFormData = TeamsArrayBulder(newFormData);
    let action;

    if (!EditMode) {
      action = await dispatch(Action.createnewTeam(updateFormData));
    } else {
      action = await dispatch(
        Action.editTeam({ updateFormData, id: EditMode })
      );
    }
    resetForm();
    setSubmitting(false);

    if (action.payload.success) {
      history.push("/teams");
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    let fromdata = new FormData();
    fromdata.append("image", file);
    await uploadPicture(fromdata);
  };

  return (
    <div className="body">
      <Header />
      <AddNewTeamComp
        handleImageUpload={handleImageUpload}
        initialValues={initialValues}
        handleFormSubmit={submitFormhandler}
      />
      <Footer />
    </div>
  );
};

export default AddNewPlayer;
