export function PlayerArrayBulder(formvaluse) {
  if (
    formvaluse.position === 'POR-Classico' ||
    formvaluse.position === 'POR-Moderno'
  ) {
    let poss = [
      formvaluse.poss0,
      formvaluse.poss1,
      formvaluse.poss2,
      formvaluse.poss3,
      formvaluse.poss4,
    ];

    let non_poss = [
      formvaluse.non_poss0,
      formvaluse.non_poss1,
      formvaluse.non_poss2,
      formvaluse.non_poss3,
      formvaluse.non_poss4,
      formvaluse.non_poss5,
      formvaluse.non_poss6,
      formvaluse.non_poss7,
    ];

    let men = [
      formvaluse.men0,
      formvaluse.men1,
      formvaluse.men2,
      formvaluse.men3,
    ];

    const results = {
      ...formvaluse,
      skills: [
        { name: 'poss', value: poss },
        { name: 'non_poss', value: non_poss },
        { name: 'men', value: men },
      ],
    };

    return results;
  } else {
    //for non gola kepers
    let poss = [
      formvaluse.poss0,
      formvaluse.poss1,
      formvaluse.poss2,
      formvaluse.poss3,
    ];

    let non_poss = [
      formvaluse.non_poss0,
      formvaluse.non_poss1,
      formvaluse.non_poss2,
      formvaluse.non_poss3,
      formvaluse.non_poss4,
      formvaluse.non_poss5,
      formvaluse.non_poss6,
      formvaluse.non_poss7,
      formvaluse.non_poss8,
      formvaluse.non_poss9,
      formvaluse.non_poss10,
      formvaluse.non_poss11,
      formvaluse.non_poss12,
    ];

    let men = [
      formvaluse.men0,
      formvaluse.men1,
      formvaluse.men2,
      formvaluse.men3,
      formvaluse.men4,
    ];

    const results = {
      ...formvaluse,
      skills: [
        { name: 'poss', value: poss },
        { name: 'non_poss', value: non_poss },
        { name: 'men', value: men },
      ],
    };

    return results;
  }
}

export const PlayerInitialValuesBuilder = (serverPayload) => {
  if (
    serverPayload.position === 'POR-Classico' ||
    serverPayload.position === 'POR-Moderno'
  ) {
    let poss = {
      poss0: serverPayload.skills[0].value[0],
      poss1: serverPayload.skills[0].value[1],
      poss2: serverPayload.skills[0].value[2],
      poss3: serverPayload.skills[0].value[3],
      poss4: serverPayload.skills[0].value[4],
    };
    let non_poss = {
      non_poss0: serverPayload.skills[1].value[0],
      non_poss1: serverPayload.skills[1].value[1],
      non_poss2: serverPayload.skills[1].value[2],
      non_poss3: serverPayload.skills[1].value[3],
      non_poss4: serverPayload.skills[1].value[4],
      non_poss5: serverPayload.skills[1].value[5],
      non_poss6: serverPayload.skills[1].value[6],
      non_poss7: serverPayload.skills[1].value[7],
    };

    let men = {
      men0: serverPayload.skills[2].value[0],
      men1: serverPayload.skills[2].value[1],
      men2: serverPayload.skills[2].value[2],
      men3: serverPayload.skills[2].value[3],
    };
    const modeifoed = {
      ...serverPayload,
      ...poss,
      ...non_poss,
      ...men,
    };
    return modeifoed;
  } else {
    let poss = {
      poss0: serverPayload.skills[0].value[0],
      poss1: serverPayload.skills[0].value[1],
      poss2: serverPayload.skills[0].value[2],
      poss3: serverPayload.skills[0].value[3],
    };
    let non_poss = {
      non_poss0: serverPayload.skills[1].value[0],
      non_poss1: serverPayload.skills[1].value[1],
      non_poss2: serverPayload.skills[1].value[2],
      non_poss3: serverPayload.skills[1].value[3],
      non_poss4: serverPayload.skills[1].value[4],
      non_poss5: serverPayload.skills[1].value[5],
      non_poss6: serverPayload.skills[1].value[6],
      non_poss7: serverPayload.skills[1].value[7],
      non_poss8: serverPayload.skills[1].value[8],
      non_poss9: serverPayload.skills[1].value[9],
      non_poss10: serverPayload.skills[1].value[10],
      non_poss11: serverPayload.skills[1].value[11],
      non_poss12: serverPayload.skills[1].value[12],
    };

    let men = {
      men0: serverPayload.skills[2].value[0],
      men1: serverPayload.skills[2].value[1],
      men2: serverPayload.skills[2].value[2],
      men3: serverPayload.skills[2].value[3],
      men4: serverPayload.skills[2].value[4],
    };
    const modeifoed = {
      ...serverPayload,
      ...poss,
      ...non_poss,
      ...men,
    };
    return modeifoed;
  }
};
