import { Field, Formik } from "formik";
import React from "react";
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { ForgetPasswordFormValidation } from "../../validation";

const ForgotPass = (props) => {
  const { handleFormSubmit } = props;
  return (
    <>
      <section>
        <div className="container-fluid login-container">
          <div className="row align-items-center">
            <div className="col-md-6 text-center">
              <img className="img-fluid" src={logo} alt="" />
            </div>
            <div className="col-md-6 login-form-2 d-md-flex align-items-center">
              <div className="w-100">
                <h1>Reset Password</h1>

                <Formik
                  validationSchema={ForgetPasswordFormValidation}
                  onSubmit={handleFormSubmit}
                  initialValues={{
                    email: "",
                  }}
                >
                  {(formik) => {
                    const { handleSubmit, errors, touched, isSubmitting } =
                      formik;
                    return (
                      <form onSubmit={handleSubmit}>
                        <p className="my-4">
                          Remember your password? <Link to="/">Login</Link>
                        </p>
                        <fieldset className="new-file-input login-fields">
                          <legend className="file-input__label">
                            Enter Email
                          </legend>
                          <Field
                            name="email"
                            type="email"
                            className="form-control file-input__input"
                            errors={errors}
                            touched={touched}
                          />
                        </fieldset>

                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="btnprimary"
                          errors={errors}
                          touched={touched}
                        >
                          Send Instructions
                        </button>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ForgotPass;
