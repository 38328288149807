import React from "react";
import graph1 from "../../assets/images/greencheck.png";
import graph2 from "../../assets/images/crossicon.png";
import graph3 from "../../assets/images/graphicon.png";
import weightIcon from "../../assets/images/weight-icon.png";
import heightIcon from "../../../src/assets/images/height-icon.png";

const Home = (props) => {
  const { twoPlayersValues, teams } = props;
  if (twoPlayersValues.length === 0) {
    return (
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-md-6">
            <h3 className="mt-150 mb-300 no-player-heading">
              No player selected
            </h3>
          </div>
        </div>
      </div>
    );
  }

  const Physical = ["velocità", "esplosività", "fisicità", "resistenza"];

  const headingText = [
    "qualità fisiche",
    "qualità tecniche",
    "qualità mentali",
  ];

  const GoalkeperPhysicalInputFields = [
    "velocità",
    "esplosività",
    "fisicità",
    "resistenza",
    "salto",
  ];

  const MentalSkillsFields = [
    "intelligenza",
    "attenzione",
    "personalità",
    "comunicazione",
    "fantasia",
  ];

  const GoalkeperMentalSkillslabel = [
    "coraggio",
    "gestione",
    "personalità",
    "comunicazione",
  ];

  const TechnicalInputFields = [
    "senza palla",
    "marcatura",
    "anticipo",
    "capacità tattiche",
    "posizionamento",
    "passaggio",
    "dribbling",
    "finalizzazione",
    "gioco aereo",
    "transizione positiva",
    "transizione negativa",
    "calci piazzati a favore",
    "calci piazzato contro",
  ];

  const GoalkeperTechnicalInputFields = [
    "gioco con i piedi",
    "gioco con le mani",
    "parate",
    "posizionanento",
    "uscite alte",
    "uscite basse",
    "lettura traiettoria",
    "tempismo",
  ];

  let player1;
  let player2;

  if (twoPlayersValues[0]._id === teams.team1) {
    player1 = twoPlayersValues[1];
    player2 = twoPlayersValues[0];
  } else {
    player2 = twoPlayersValues[1];
    player1 = twoPlayersValues[0];
  }

  return (
    <div className="compare-detail">
      <section className="clubwrapper">
        <div className="container-fluid">
          <div className="row mt-5">
            <div className="col-md-3">
              <div className="blueshade">
                <div className="card-header">
                  <h4 className="flex-grow-1">{player2.surName}</h4>
                  <img
                    className="mx-auto rounded-circle"
                    style={{ objectFit: "cover", objectPosition: "center" }}
                    src={player2.avator}
                    alt="topanalyst"
                    width="100"
                    height="100"
                  />
                </div>

                <div className="clubdetail teamdetail compare-detail">
                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Nationality: </span>
                      <span> {player2.nationality} </span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>TeamName: </span>
                      <span> {player2.teamName[0]} </span>
                    </h6>
                  </div>

                  <div className="field-styled">
                    <h6>
                      <span>Position: </span>
                      <span>{player2.position}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="HW-row">
                    <div>
                      <img src={heightIcon} alt="" />
                      <span> {player2.height} </span>
                    </div>

                    <div>
                      <img src={weightIcon} alt="" />
                      <span> {player2.weight} </span>
                    </div>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Foot: </span>
                      <span>{player2.foot}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Career: </span>
                      <span>{player2.career}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Matches Name: </span>
                      <span>{player2.match.matchName}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Matches Dates: </span>
                      <span>{player2.match.matchDates}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Matches Result: </span>
                      <span>{player2.match.matchResults}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Match Weather: </span>
                      <span>{player2.match.matchWeather}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Scout/Analyst: </span>
                      <span>{player2.scouteanalyst}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>More Information: </span>
                      <span>{player2.moreInformation}</span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="blueshade play-style-graph">
                <p>Play Style: {player2.position}</p>
                <img
                  className="img-fluid"
                  src={player2.position && require(`../../assets/images/player-screen/${player2.position}.jpg`).default}
                  alt="Select_play_style_to_place_picture"
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="blueshade play-style-graph">
                <p>Play Style: {player1.position}</p>
                <img
                  className="img-fluid"
                  src={player1.position && require(`../../assets/images/player-screen/${player1.position}.jpg`).default}
                  alt="Select_play_style_to_place_picture"
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="blueshade">
                <div className="card-header">
                  <h4 className="flex-grow-1">{player1.surName}</h4>
                  <img
                    className="mx-auto rounded-circle"
                    style={{ objectFit: "cover", objectPosition: "center" }}
                    src={player1.avator}
                    alt="topanalyst"
                    width="100"
                    height="100"
                  />
                </div>

                <div className="clubdetail teamdetail">
                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Nationality: </span>
                      <span> {player1.nationality} </span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>TeamName: </span>
                      <span> {player1.teamName[0]} </span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Position: </span>
                      <span> {player1.position} </span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="HW-row">
                    <div>
                      <img src={heightIcon} alt="" />
                      <span> {player1.height} </span>
                    </div>

                    <div>
                      <img src={weightIcon} alt="" />
                      <span> {player1.weight} </span>
                    </div>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Foot: </span>
                      <span> {player1.foot} </span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Career: </span>
                      <span> {player1.career} </span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Matches Name: </span>
                      <span>{player1.match.matchName}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Matches Dates: </span>
                      <span>{player1.match.matchDates}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Matches Result: </span>
                      <span>{player1.match.matchResults}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Match Weather: </span>
                      <span>{player1.match.matchWeather}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>Scout/Analyst: </span>
                      <span>{player1.scouteanalyst}</span>
                    </h6>
                  </div>

                  {/* Field */}
                  <div className="field-styled">
                    <h6>
                      <span>More Information: </span>
                      <span>{player1.moreInformation}</span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="blueshade">
                    <div className="card-header description-field-header">
                      <h5>Strengths</h5>
                      <img
                        className="img-fluid"
                        src={graph1}
                        alt="topanalyst"
                      />
                    </div>
                    <div className="description-field">
                      <textarea
                        className="detail-textarea form-control bg-darkblue"
                        rows={4}
                        readOnly
                      >
                        {player2.strength}
                      </textarea>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="blueshade">
                    <div className="card-header description-field-header">
                      <h5>Weaknesses</h5>
                      <img
                        className="img-fluid"
                        src={graph2}
                        alt="topanalyst"
                      />
                    </div>
                    <div className="description-field">
                      <textarea
                        className="detail-textarea form-control bg-darkblue"
                        rows={4}
                        readOnly
                      >
                        {player2.weakness}
                      </textarea>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="blueshade">
                    <div className="card-header description-field-header">
                      <h5>Vote</h5>
                      <img
                        className="img-fluid"
                        src={graph3}
                        alt="topanalyst"
                      />
                    </div>
                    <div className="description-field">
                      <textarea
                        className="detail-textarea form-control bg-darkblue"
                        rows={2}
                        readOnly
                      >
                        {player2.vote}
                      </textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="blueshade">
                    <div className="card-header description-field-header">
                      <h5>Strengths</h5>
                      <img
                        className="img-fluid"
                        src={graph1}
                        alt="topanalyst"
                      />
                    </div>
                    <div className="description-field">
                      <textarea
                        className="detail-textarea form-control bg-darkblue"
                        rows={4}
                        readOnly
                      >
                        {player1.strength}
                      </textarea>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="blueshade">
                    <div className="card-header description-field-header">
                      <h5>Weaknesses</h5>
                      <img
                        className="img-fluid"
                        src={graph2}
                        alt="topanalyst"
                      />
                    </div>
                    <div className="description-field">
                      <textarea
                        className="detail-textarea form-control bg-darkblue"
                        rows={4}
                        readOnly
                      >
                        {player1.weakness}
                      </textarea>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="blueshade">
                    <div className="card-header description-field-header">
                      <h5>Vote</h5>
                      <img
                        className="img-fluid"
                        src={graph3}
                        alt="topanalyst"
                      />
                    </div>
                    <div className="description-field">
                      <textarea
                        className="detail-textarea form-control bg-darkblue"
                        rows={2}
                        readOnly
                      >
                        {player1.vote}
                      </textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="comparisonwrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="row">

              <div className="col-md-6">
                  <div className="comparisontable">
                    {["POR-Moderno", "POR-Classico"].includes(player2.position)
                      ? player2.skills.map((obj, outerindex) => {
                          return (
                            <div key={outerindex}>
                              <div className="row">
                                <div className="col-md-12">
                                  <p>{headingText[outerindex]}</p>
                                </div>
                              </div>

                              <div>
                                {obj.value.map((el, index) => {
                                  return (
                                    <div key={index}>
                                      <div className="flex-grow-1 d-flex justify-content-between">
                                        <p className="color-white">{el}</p>

                                        <div className="inputrange">
                                          <div className="progress">
                                            <div
                                              className="progress-bar"
                                              role="progressbar"
                                              style={{
                                                width: Number(el) * 10 + "%",
                                              }}
                                              aria-valuenow="25"
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                            >
                                              {el}
                                            </div>
                                          </div>
                                        </div>

                                        <p>
                                          {(outerindex === 2 &&
                                            GoalkeperMentalSkillslabel[
                                              index
                                            ]) ||
                                            (outerindex === 1 &&
                                              GoalkeperTechnicalInputFields[
                                                index
                                              ]) ||
                                            (outerindex === 0 &&
                                              GoalkeperPhysicalInputFields[
                                                index
                                              ])}
                                        </p>

                                        {/* <p className="">{obj.name + (index + 1)}</p> */}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })
                      : player2.skills.map((obj, outerindex) => {
                          return (
                            <div
                              key={outerindex}
                              className="progress-bar-compare"
                            >
                              <h4>{headingText[outerindex]}</h4>
                              {obj.value.map((el, index) => {
                                return (
                                  <div className="progress-bar-row" key={index}>
                                    <p>
                                      {(outerindex === 2 &&
                                        MentalSkillsFields[index]) ||
                                        (outerindex === 1 &&
                                          TechnicalInputFields[index]) ||
                                        (outerindex === 0 && Physical[index])}
                                    </p>

                                    <div className="inputrange">
                                      <div className="progress">
                                        <div
                                          className="progress-bar"
                                          role="progressbar"
                                          style={{
                                            width: Number(el) * 10 + "%",
                                          }}
                                          aria-valuenow="25"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                        >
                                          {el}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                  </div>
                </div>
                
                <div className="col-md-6">
                  <div className="comparisontable">
                    {["POR-Moderno", "POR-Classico"].includes(player1.position)
                      ? player1.skills.map((obj, outerindex) => {
                          return (
                            <div key={outerindex}>
                              <div className="row">
                                <div className="col-md-12">
                                  <p>{headingText[outerindex]}</p>
                                </div>
                              </div>

                              <div>
                                {obj.value.map((el, index) => {
                                  return (
                                    <div key={index}>
                                      <div className="flex-grow-1 d-flex justify-content-between">
                                        <p className="color-white">{el}</p>

                                        <div className="inputrange">
                                          <div className="progress">
                                            <div
                                              className="progress-bar"
                                              role="progressbar"
                                              style={{
                                                width: Number(el) * 10 + "%",
                                              }}
                                              aria-valuenow="25"
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                            >
                                              {el}
                                            </div>
                                          </div>
                                        </div>

                                        <p>
                                          {(outerindex === 2 &&
                                            GoalkeperMentalSkillslabel[
                                              index
                                            ]) ||
                                            (outerindex === 1 &&
                                              GoalkeperTechnicalInputFields[
                                                index
                                              ]) ||
                                            (outerindex === 0 &&
                                              GoalkeperPhysicalInputFields[
                                                index
                                              ])}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })
                      : player1.skills.map((obj, outerindex) => {
                          return (
                            <div
                              key={outerindex}
                              className="progress-bar-compare"
                            >
                              <h4>{headingText[outerindex]}</h4>
                              {obj.value.map((el, index) => {
                                return (
                                  <div className="progress-bar-row" key={index}>
                                    <p>
                                      {(outerindex === 2 &&
                                        MentalSkillsFields[index]) ||
                                        (outerindex === 1 &&
                                          TechnicalInputFields[index]) ||
                                        (outerindex === 0 && Physical[index])}
                                    </p>

                                    <div className="inputrange">
                                      <div className="progress">
                                        <div
                                          className="progress-bar"
                                          role="progressbar"
                                          style={{
                                            width: Number(el) * 10 + "%",
                                          }}
                                          aria-valuenow="25"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                        >
                                          {el}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Home;
