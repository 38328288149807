// {{url}}/user/login/

import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../index";

export const createNewPlayer = createAsyncThunk(
  "user/creatsdfseNewPlayer/post",
  async (formdata, store) => {
    return await axios.post("/player", formdata);
  }
);

export const getAllPlayer = createAsyncThunk(
  "getplayer/getAllPlayersdf/get",
  async (roal, store) => {
    return await axios.get("/player");
  }
);

export const getSpecifiPlayer = createAsyncThunk(
  "getteam/sdfsdfs/get",
  async (id, store) => {
    return await axios.get(`/player/${id}`);
  }
);

export const editPlayer = createAsyncThunk(
  "getteam/editPlfsdayer/get",
  async ({ updateFormData, id }, store) => {
    return await axios.patch(`/player/${id}`, updateFormData);
  }
);

export const deletePlayer = createAsyncThunk(
  "getteam/sd/get",
  async (id, store) => {
    return await axios.delete(`/player/${id}`);
  }
);

export const getTeams_name_and_id = createAsyncThunk(
  "user/getTeams_name_and_id/post",
  async (formdata, store) => {
    return await axios.get("/team/name-and-id");
  }
);

export const CompareTwoPlayers = createAsyncThunk(
  "getteam/CompareTwoPlayers/get",
  async (paylod, store) => {
    return await axios.post(`/player/compare`, paylod);
  }
);

export const GetPlayerSkillsAvg = createAsyncThunk(
  "getteam/GetPlayerSkillsAvg/get",
  async (id, store) => {
    return await axios.get(`/player/skillsAvg/${id}`);
  }
);
