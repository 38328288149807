import React from "react";
import "react-circular-progressbar/dist/styles.css";

const Player = (props) => {
  const { SinglePlayer } = props;
  const { matchDates, matchName, matchResults, matchWeather } =
    SinglePlayer.match || {};

  const {
    name,
    surName,
    DOB,
    nationality,
    league,
    height,
    weight,
    agency,
    position,
    foot,
    career,
    avator,
    injuries,
    moreInformation,
    team,
  } = SinglePlayer;

  const separator = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div>
      <section className="clubwrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="detail-img">
                <img
                  className="rounded-circle"
                  style={{ objectFit: "cover" }}
                  width="70"
                  height="70"
                  src={avator}
                  alt="topanalyst"
                />
                <h5>{surName}</h5>
              </div>
            </div>

            <div className="col-md-3">
              <div className="blueshade">
                <div className="clubdetail teamdetail player-detail">
                  <div className="field-styled">
                    <h6>
                      <span>Name: </span> <span>{name}</span>
                    </h6>
                  </div>

                  <div className="field-styled">
                    <h6>
                      <span>Date of Birth: </span>{" "}
                      <span>{new Date(DOB).toDateString()}</span>
                    </h6>
                  </div>

                  <div className="field-styled">
                    <h6>
                      <span>Nationality: </span> <span>{nationality}</span>
                    </h6>
                  </div>

                  <div className="field-styled">
                    <h6>
                      <span>Club: </span> <span>{team}</span>
                    </h6>
                  </div>

                  <div className="field-styled">
                    <h6>
                      <span>League: </span> <span>{league}</span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="blueshade">
                <div className="clubdetail teamdetail player-detail">
                  <div className="field-styled">
                    <h6>
                      <span>Weight: </span>{" "}
                      <span>{separator(weight || 0)}</span>
                    </h6>
                  </div>

                  <div className="field-styled">
                    <h6>
                      <span> Height: </span>{" "}
                      <span>{separator(height || 0)}</span>
                    </h6>
                  </div>

                  <div className="field-styled">
                    <h6>
                      <span>Agency: </span> <span>{agency}</span>
                    </h6>
                  </div>

                  <div className="field-styled">
                    <h6>
                      <span>Matches: </span> <span>{matchName}</span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="blueshade">
                <div className="clubdetail teamdetail player-detail">
                  <div className="field-styled">
                    <h6>
                      <span>Results: </span> <span>{matchResults}</span>
                    </h6>
                  </div>

                  <div className="field-styled">
                    <h6>
                      <span>Matches Dates: </span> <span>{matchDates}</span>
                    </h6>
                  </div>

                  <div className="field-styled">
                    <h6>
                      <span>Matches Weather: </span> <span>{matchWeather}</span>
                    </h6>
                  </div>

                  <div className="field-styled">
                    <h6>
                      <span>Play Style: </span> <span>{position}</span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="clubdetail teamdetail player-detail">
                <div className="field-styled">
                  <h6>
                    <span>Foot: </span> <span>{foot}</span>
                  </h6>
                </div>

                <div className="field-styled">
                  <h6>
                    <span>Career: </span> <span>{career}</span>
                  </h6>
                </div>

                <div className="field-styled">
                  <h6>
                    <span>More Information:</span>
                    <span>{moreInformation}</span>
                  </h6>
                </div>

                <div className="field-styled">
                  <h6>
                    <span>Injuries:</span> <span>{injuries}</span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Player;
