import * as Yup from "yup";

export const LoginFormValidation = Yup.object().shape({
  email: Yup.string().email("Not a valid email").required("Email is required"),

  password: Yup.string().required("Password is required"),
});

export const SingupFormValidation = Yup.object().shape({
  firstName: Yup.string()
    .required("First name is requied")
    .min(2, "Enter 2 char min"),
  lastName: Yup.string()
    .required("Last name is required")
    .min(2, "Enter 2 char min"),
  email: Yup.string().email("Not a valid email").required("Email is required"),

  password: Yup.string()
    .required("Required")
    .matches(
      /^(?=.*[a-z,A-Z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/g,
      "Min 6 Char, One Uppercase,  One Number"
    ),
});

export const ForgetPasswordFormValidation = Yup.object().shape({
  email: Yup.string().email("Not a valid email").required("Email is required"),
});

export const EditProfileFormValidation = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),

  firstName: Yup.string()
    .required("Name is required")
    .min(2, "Name is too short"),

  lastName: Yup.string()
    .required("Name is required")
    .min(2, "Name is too short"),
});

export const changepassFormValidation = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),

  name: Yup.string().required("Name is required").min(2, "Name is too short"),
});

export const UplodnewpasswordFormValidation = Yup.object().shape({
  oddpassword: Yup.string().required("required"),

  newpasswordConform: Yup.string()
    .required("Required")
    .min(2, "Password is too short"),
  newpassword: Yup.string()
    .oneOf([Yup.ref("newpasswordConform"), null], "Passwords don't match!")
    .min(2, "Password is too short")
    .required("Required"),
});

export const resPassFormValidation = Yup.object().shape({
  newpassword: Yup.string()
    .required("Required")
    .min(2, "Password is too short"),
  passwordConform: Yup.string()
    .oneOf([Yup.ref("newpassword"), null], "Password don't match!")
    .min(2, "Password is too short")
    .required("Required"),
});

export const addNewPlayerPersonalData = Yup.object().shape({
  name: Yup.string().required("Required"),
  surName: Yup.string().required("Required"),
  DOB: Yup.string().required("Required"),
  nationality: Yup.string().required("Required"),
  league: Yup.string().required("Required"),
  height: Yup.string().required("Required"),
  weight: Yup.string().required("Required"),
  agency: Yup.string().required("Required"),
  foot: Yup.string().required("Required"),

  matchDates: Yup.string().required("Required"),
  matchName: Yup.string().required("Required"),
  matchResults: Yup.string().required("Required"),
  matchWeather: Yup.string().required("Required"),
  career: Yup.string().required("Required"),
  injuries: Yup.string().required("Required"),
  moreInformation: Yup.string().required("Required"),
  // team: Yup.string().required("Required"),
  position: Yup.string().required("Required"),

  strength: Yup.string().required("Required"),
  weakness: Yup.string().required("Required"),
  vote: Yup.string().required("Required"),
  potentialvote: Yup.string().required("Required"),
  scouteanalyst: Yup.string().required("Required"),
  playstyledetail: Yup.string().required("Required"),

  poss0: Yup.number().required("Required").max(10, "max 10").min(0, "min 0"),
  poss1: Yup.number().required("Required").max(10, "max 10").min(0, "min 0"),
  poss2: Yup.number().required("Required").max(10, "max 10").min(0, "min 0"),
  poss3: Yup.number().required("Required").max(10, "max 10").min(0, "min 0"),

  //here is other skills values

  non_poss0: Yup.number()
    .required("Required")
    .max(10, "max 10")
    .min(0, "min 0"),

  non_poss1: Yup.number()
    .required("Required")
    .max(10, "max 10")
    .min(0, "min 0"),
  non_poss2: Yup.number()
    .required("Required")
    .max(10, "max 10")
    .min(0, "min 0"),
  non_poss3: Yup.number()
    .required("Required")
    .max(10, "max 10")
    .min(0, "min 0"),
  non_poss4: Yup.number()
    .required("Required")
    .max(10, "max 10")
    .min(0, "min 0"),
  non_poss5: Yup.number()
    .required("Required")
    .max(10, "max 10")
    .min(0, "min 0"),
  non_poss6: Yup.number()
    .required("Required")
    .max(10, "max 10")
    .min(0, "min 0"),
  non_poss7: Yup.number()
    .required("Required")
    .max(10, "max 10")
    .min(0, "min 0"),
  non_poss8: Yup.number()
    .required("Required")
    .max(10, "max 10")
    .min(0, "min 0"),
  non_poss9: Yup.number()
    .required("Required")
    .max(10, "max 10")
    .min(0, "min 0"),
  non_poss10: Yup.number()
    .required("Required")
    .max(10, "max 10")
    .min(0, "min 0"),
  non_poss11: Yup.number()
    .required("Required")
    .max(10, "max 10")
    .min(0, "min 0"),
  non_poss12: Yup.number()
    .required("Required")
    .max(10, "max 10")
    .min(0, "min 0"),

  //last player skills values
  men0: Yup.number().required("Required").max(10, "max 10").min(0, "min 0"),
  men1: Yup.number().required("Required").max(10, "max 10").min(0, "min 0"),
  men2: Yup.number().required("Required").max(10, "max 10").min(0, "min 0"),
  men3: Yup.number().required("Required").max(10, "max 10").min(0, "min 0"),
  men4: Yup.number().required("Required").max(10, "max 10").min(0, "min 0"),
});

// Modeified form
export const addNewPlayerPersonalDataModeiedWithGoalkeper = Yup.object().shape({
  name: Yup.string().required("Required"),
  surName: Yup.string().required("Required"),
  DOB: Yup.string().required("Required"),
  nationality: Yup.string().required("Required"),
  league: Yup.string().required("Required"),
  height: Yup.string().required("Required"),
  weight: Yup.string().required("Required"),
  agency: Yup.string().required("Required"),
  foot: Yup.string().required("Required"),

  matchDates: Yup.string().required("Required"),
  matchName: Yup.string().required("Required"),
  matchResults: Yup.string().required("Required"),
  matchWeather: Yup.string().required("Required"),
  career: Yup.string().required("Required"),
  injuries: Yup.string().required("Required"),
  moreInformation: Yup.string().required("Required"),
  team: Yup.string().required("Required"),
  position: Yup.string().required("Required"),

  strength: Yup.string().required("Required"),
  weakness: Yup.string().required("Required"),
  vote: Yup.string().required("Required"),
  potentialvote: Yup.string().required("Required"),
  scouteanalyst: Yup.string().required("Required"),
  playstyledetail: Yup.string().required("Required"),

  poss0: Yup.number().required("Required").max(10, "max 10").min(0, "min 0"),
  poss1: Yup.number().required("Required").max(10, "max 10").min(0, "min 0"),
  poss2: Yup.number().required("Required").max(10, "max 10").min(0, "min 0"),
  poss3: Yup.number().required("Required").max(10, "max 10").min(0, "min 0"),
  poss4: Yup.number().required("Required").max(10, "max 10").min(0, "min 0"),

  //here is other skills values

  non_poss0: Yup.number()
    .required("Required")
    .max(10, "max 10")
    .min(0, "min 0"),
  non_poss1: Yup.number()
    .required("Required")
    .max(10, "max 10")
    .min(0, "min 0"),
  non_poss2: Yup.number()
    .required("Required")
    .max(10, "max 10")
    .min(0, "min 0"),
  non_poss3: Yup.number()
    .required("Required")
    .max(10, "max 10")
    .min(0, "min 0"),
  non_poss4: Yup.number()
    .required("Required")
    .max(10, "max 10")
    .min(0, "min 0"),
  non_poss5: Yup.number()
    .required("Required")
    .max(10, "max 10")
    .min(0, "min 0"),
  non_poss6: Yup.number()
    .required("Required")
    .max(10, "max 10")
    .min(0, "min 0"),
  non_poss7: Yup.number()
    .required("Required")
    .max(10, "max 10")
    .min(0, "min 0"),

  //last player skills values
  men0: Yup.number().required("Required").max(10, "max 10").min(0, "min 0"),
  men1: Yup.number().required("Required").max(10, "max 10").min(0, "min 0"),
  men2: Yup.number().required("Required").max(10, "max 10").min(0, "min 0"),
  men3: Yup.number().required("Required").max(10, "max 10").min(0, "min 0"),
});

export const addNewTeamPersonalData = Yup.object().shape({
  teamName: Yup.string().required("Required"),
  actualposition: Yup.string().required("Required"),
  coach: Yup.string().required("Required"),
  // nationality: Yup.string().required("Required"),
  league: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  season: Yup.string().required("Required"),
  matches: Yup.string().required("Required"),
  matches_results: Yup.string().required("Required"),
  matches_dates: Yup.string().required("Required"),
  matches_weather: Yup.string().required("Required"),
  yellow_cards: Yup.string().required("Required"),
  red_cards: Yup.string().required("Required"),
  seasonresume: Yup.string().required("Required"),
  moreInformation: Yup.string().required("Required"),
  injuries: Yup.string().required("Required"),
  playstyledetail: Yup.string().required("Required"),

  //

  weakness: Yup.string().required("Required"),
  strength: Yup.string().required("Required"),
  vote: Yup.string().required("Required"),
  scouteanalyst: Yup.string().required("Required"),

  poss0: Yup.number().required("Required").max(10, "max 10").min(0, "min 0"),
  poss1: Yup.number().required("Required").max(10, "max 10").min(0, "min 0"),
  poss2: Yup.number().required("Required").max(10, "max 10").min(0, "min 0"),
  poss3: Yup.number().required("Required").max(10, "max 10").min(0, "min 0"),
  poss4: Yup.number().required("Required").max(10, "max 10").min(0, "min 0"),
  poss5: Yup.number().required("Required").max(10, "max 10").min(0, "min 0"),
  poss6: Yup.number().required("Required").max(10, "max 10").min(0, "min 0"),
  poss7: Yup.number().required("Required").max(10, "max 10").min(0, "min 0"),

  //here is other skills values

  non_poss0: Yup.number()
    .required("Required")
    .max(10, "max 10")
    .min(0, "min 0"),
  non_poss1: Yup.number()
    .required("Required")
    .max(10, "max 10")
    .min(0, "min 0"),
  non_poss2: Yup.number()
    .required("Required")
    .max(10, "max 10")
    .min(0, "min 0"),
  non_poss3: Yup.number()
    .required("Required")
    .max(10, "max 10")
    .min(0, "min 0"),
  non_poss4: Yup.number()
    .required("Required")
    .max(10, "max 10")
    .min(0, "min 0"),
  non_poss5: Yup.number()
    .required("Required")
    .max(10, "max 10")
    .min(0, "min 0"),
  non_poss6: Yup.number()
    .required("Required")
    .max(10, "max 10")
    .min(0, "min 0"),
  non_poss7: Yup.number()
    .required("Required")
    .max(10, "max 10")
    .min(0, "min 0"),

  //last player skills values
  men0: Yup.number().required("Required").max(10, "max 10").min(0, "min 0"),
  men1: Yup.number().required("Required").max(10, "max 10").min(0, "min 0"),
  men2: Yup.number().required("Required").max(10, "max 10").min(0, "min 0"),
  men3: Yup.number().required("Required").max(10, "max 10").min(0, "min 0"),
  men4: Yup.number().required("Required").max(10, "max 10").min(0, "min 0"),
  men5: Yup.number().required("Required").max(10, "max 10").min(0, "min 0"),
});
