import React, { useEffect, useState } from "react";
import * as Action from "../../action";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { setTwoPlayersClear } from "./slice";
import { useDispatch, useSelector } from "react-redux";
import PlayerCompComponent from "../../components/Player/PlayerComp";
import { selectPlayerArray, selecttwoPlayersValues } from "../player/selector";

const DropDowns = () => {
  const dispatch = useDispatch();
  const PlayerArray = useSelector(selectPlayerArray);
  const twoPlayersValues = useSelector(selecttwoPlayersValues);
  const [teams, setteams] = useState({ team1: "", team2: "", error: false });

  useEffect(() => {
    dispatch(Action.getAllPlayer());
    dispatch(setTwoPlayersClear());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickPlayer1 = (event) => {
    if (event.target.value) {
      setteams({ ...teams, error: false, team1: event.target.value });
    }
  };

  const handleClickPlayer2 = (event) => {
    if (event.target.value) {
      setteams({ ...teams, error: false, team2: event.target.value });
    }
  };

  const handelCompareClick = () => {
    if (teams.team1 && teams.team2 && teams.team1 !== teams.team2) {
      const payload = { player: [teams.team1, teams.team2] };

      dispatch(Action.CompareTwoPlayers(payload));
    } else {
      setteams({ ...teams, error: true });
    }
  };
  return (
    <>
      <Header />
      <div className="body pt-5 fullheightWrapper">
        <div className="container-fluid">
          <div className="row align-items-end">
            <div className="col-md-3">
              <fieldset className="new-file-input mb-md-0">
                <legend className="file-input__label">Select 1st Player</legend>
                <select
                  onChange={handleClickPlayer1}
                  className="form-control file-input__input"
                >
                  {PlayerArray.length > 0 ? (
                    <option value="">{"Player 1"}</option>
                  ) : (
                    <option value="">{"no player found"}</option>
                  )}

                  {PlayerArray.map((SingleTeam, index) => {
                    return (
                      <option value={SingleTeam._id} key={index}>
                        {SingleTeam.surName}
                      </option>
                    );
                  })}
                </select>
              </fieldset>
            </div>

            <div className="col-md-3">
              <fieldset className="new-file-input mb-md-0">
                <legend className="file-input__label">Select 2nd Player</legend>
                <select
                  onChange={handleClickPlayer2}
                  className="form-control file-input__input"
                >
                  {PlayerArray.length > 0 ? (
                    <option value="">{"Player 2"}</option>
                  ) : (
                    <option value="">{"no player found"}</option>
                  )}
                  {PlayerArray.map((SingleTeam, index) => {
                    return (
                      <option value={SingleTeam._id} key={index}>
                        {SingleTeam.surName}
                      </option>
                    );
                  })}
                </select>
              </fieldset>
            </div>

            <div className="col-md-2">
              <button
                onClick={handelCompareClick}
                className="btn btnprimary compare-btn"
              >
                Compare
              </button>
            </div>
            {teams.error && (
              <div className="text-danger">
                <h6>Select two players with different names</h6>
              </div>
            )}
          </div>
        </div>

        <PlayerCompComponent
          teams={teams}
          twoPlayersValues={twoPlayersValues}
        />
      </div>
      <Footer />
    </>
  );
};

export default DropDowns;
