import { createSlice } from "@reduxjs/toolkit";
// Slice
const loaderslice = createSlice({
  name: "loader",
  initialState: {
    loader: false,
  },
  reducers: {
    setloader: (state, action) => {
      state.loader = action.payload;
    },
  },
});

export const { setloader } = loaderslice.actions;

export default loaderslice.reducer;
