import React from "react";
import logo from "../../assets/images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../container/User/user.slice";
import dummyuser from "../../assets/images/dummyuser.png";
import { Link, NavLink, useHistory } from "react-router-dom";
import { selectCurrentUser } from "../../container/User/user.select";

const Header = () => {
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleLogoutClick = () => {
    dispatch(logout());
    history.push("/");
  };

  return (
    <>
      <header className="adminheader site-header" id="site-header">
        <nav className="navbar navbar-expand-lg navbar-light bg-light py-0 pr-md-0">
          <div className="container-fluid pr-md-0">
            <a className="logo" href="/">
              <img width="175" className="img-fluid " src={logo} alt="" />
            </a>

            <div
              className="collapse navbar-collapse justify-content-center"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mx-auto">
                <li className="nav-item">
                  <NavLink className="nav-link" to="/players">
                    Player Analysis
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/PlayerComprasion">
                    Player Comparison
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink className="nav-link" to="/teams">
                    Team Analysis
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink className="nav-link" to="/teamComprasion">
                    Team Comparison
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/teams/goalkepers">
                    Goalkeeper Analysis
                  </NavLink>
                </li>
              </ul>
            </div>

            <div className="d-flex formobile">
              <div className="dropdown pointer d-flex align-items-center">
                <div className="dropdown userdropdown">
                  <div>
                    <img
                      width="50"
                      height="50"
                      className="dropdown-toggle rounded-circle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      src={currentUser.avator || dummyuser}
                      alt=""
                    />
                    <div
                      className="dropdown-menu dropdown-menu-right"
                      aria-labelledby="usericon"
                    >
                      <Link className="dropdown-item" to="/edit-profile">
                        <i className="fas fa-user mr-3" />
                        Edit Profile
                      </Link>
                      <Link
                        onClick={handleLogoutClick}
                        className="dropdown-item"
                        to="#"
                      >
                        <i className="fas fa-sign-out-alt mr-3" />
                        Logout
                      </Link>
                    </div>
                  </div>
                  <div className="user-info">
                    <h5>{`${currentUser.firstName} ${currentUser.lastName}`}</h5>
                    {/* <p>scout/analyst</p> */}
                  </div>
                </div>
              </div>
              <button
                className="navbar-toggler "
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="fas fa-bars" />
              </button>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};
export default Header;
