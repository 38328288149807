// {{url}}/user/login/

import { createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../index";

export const createnewTeam = createAsyncThunk(
  "user/getCurrnetUser/post",
  async (formdata, store) => {
    return await axios.post("/team", formdata);
  }
);

export const getAllteam = createAsyncThunk(
  "getplayer/player/get",
  async (role, store) => {
    return await axios.get("/team");
  }
);

export const getSpecificTeam = createAsyncThunk(
  "getteam/editTeam/get",
  async (id, store) => {
    return await axios.get(`/team/${id}`);
  }
);

export const editTeam = createAsyncThunk(
  "getteam/team/get",
  async ({ updateFormData, id }, store) => {
    return await axios.patch(`/team/${id}`, updateFormData);
  }
);

export const deleteTeam = createAsyncThunk(
  "getteam/deleteTeam/get",
  async (id, store) => {
    return await axios.delete(`/team/${id}`);
  }
);

export const CompareTwoTeams = createAsyncThunk(
  "getteam/CompareTwoTeams/get",
  async (paylod, store) => {
    return await axios.post(`/team/compare`, paylod);
  }
);

export const GetSkillsAvg = createAsyncThunk(
  "getteam/GetSkillsAvg/get",
  async (id, store) => {
    return await axios.get(`/team/skillsAvg/${id}`);
  }
);
