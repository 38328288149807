import React from "react";
import * as Action from "../../action";
import { useDispatch } from "react-redux";
import ForgotPass from "../../components/ForgotPass";

const ForgotPassCom = () => {
  const dispatch = useDispatch();
  const handleFormSubmit = async (formValues, { resetForm, setSubmitting }) => {
    const action = await dispatch(Action.ForgetPassAction(formValues));
    if (action.payload.success) {
      resetForm();
    }
    setSubmitting(false);
  };

  return <ForgotPass handleFormSubmit={handleFormSubmit} />;
};

export default ForgotPassCom;
