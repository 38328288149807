import { createSlice } from "@reduxjs/toolkit";
import {
  getAllteam,
  GetSkillsAvg,
  getSpecificTeam,
  CompareTwoTeams,
} from "./team.action";
import loginuser from "../../assets/images/dummyuser.png";

const initialState = {
  TeamsArray: [],
  SingleTeam: [],
  twoTeams: [],
  initialValues: {
    teamName: "",
    actualposition: "",
    coach: "",
    league: "",
    city: "",
    season: "",
    matches: "",
    matches_results: "",
    matches_dates: "",
    matches_weather: "",
    yellow_cards: "",
    red_cards: "",
    seasonresume: "",
    moreInformation: "",
    injuries: "",
    pic: loginuser,
    strength: "",
    weakness: "",
    vote: "",
    scouteanalyst: "",
    playstyledetail: "",
  },
  skillsAvg: [],
};

const TeamSchotes = createSlice({
  name: "TeamSchotes",
  initialState,
  reducers: {
    setInitialValues: (state, action) => {
      state.initialValues = { ...state.initialValues, ...action.payload };
    },
    setPicLink: (state, action) => {
      state.initialValues = { ...state.initialValues, pic: action.payload };
    },
    setTwoTemasClear: (state, action) => {
      state.twoTeams = [];
    },
    setInitialValuesClear: (state, action) => {
      state.initialValues = {
        teamName: "",
        actualposition: "",
        coach: "",
        league: "",
        city: "",
        season: "",
        matches: "",
        matches_results: "",
        matches_dates: "",
        matches_weather: "",
        yellow_cards: "",
        red_cards: "",
        seasonresume: "",
        moreInformation: "",
        injuries: "",
        pic: "",
        playstyledetail: "",
      };
    },
  },

  extraReducers: {
    [getAllteam.fulfilled.toString()]: (state, action) => {
      const { success, team } = action.payload;
      if (success) {
        state.TeamsArray = team;
      }
    },

    [getSpecificTeam.fulfilled.toString()]: (state, action) => {
      const { success, team } = action.payload;
      if (success) {
        state.SingleTeam = team[0];
      }
    },

    [CompareTwoTeams.fulfilled.toString()]: (state, action) => {
      const { success, Teams } = action.payload;
      if (success) {
        state.twoTeams = Teams;
      }
    },

    [GetSkillsAvg.fulfilled.toString()]: (state, action) => {
      const { success, skillsAvg } = action.payload;
      if (success) {
        state.skillsAvg = skillsAvg;
      }
    },
  },
});

export const {
  setInitialValues,
  setPicLink,
  setInitialValuesClear,
  setTwoTemasClear,
} = TeamSchotes.actions;

export default TeamSchotes.reducer;
