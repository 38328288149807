import { createDraftSafeSelector } from "@reduxjs/toolkit";

const selectDomain = (state) => state.playerReducer;

export const selectSinglePlayer = createDraftSafeSelector(
  selectDomain,
  (playerReducer) => playerReducer.playerArray
);

export const Selectsimpleteamsarray = createDraftSafeSelector(
  selectDomain,
  (playerReducer) => playerReducer.simpleteamsarray
);

export const selectinitialValues = createDraftSafeSelector(
  selectDomain,
  (playerReducer) => playerReducer.initialValues
);

export const selectPlayerArray = createDraftSafeSelector(
  selectDomain,
  (playerReducer) => playerReducer.playersArray
);

export const selecttwoPlayersValues = createDraftSafeSelector(
  selectDomain,
  (playerReducer) => playerReducer.twoPlayers
);

export const selectskillsAvg = createDraftSafeSelector(
  selectDomain,
  (playerReducer) => playerReducer.skillsAvg
);

export const selectTeams_name_and_id = createDraftSafeSelector(
  selectDomain,
  (playerReducer) => playerReducer.getTeams_name_and_id
);
