module.exports = {
  SERVER_URL:
    process.env.REACT_APP_DEVELOPMENT_URL &&
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_DEVELOPMENT_URL
      : process.env.REACT_APP_SERVER_URL,

  NODE_ENV: process.env.NODE_ENV,
  AWSS3URL: process.env.REACT_APP_SERVER_S3_URL,
};
