import { createSlice } from "@reduxjs/toolkit";
import {
  removeuserData,
  setcurrentuserandtoken,
  updatecurrentuserdata,
} from "../../utils";
import {
  loginAction,
  singnupAction,
  getCurrnetUser,
  updateUserData,
  updatePassword,
  UploadProfileAction,
} from "./user.actions";

const initialState = {
  isLogedin: false,
  token: "",
  fresUser: { email: "", password: "" },
  subscription: false,
  currentUser: {
    role: "",
    _id: "",
    name: "",
    email: "",
    avator: "",
    subscription: { active: false },
  },
};
const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    settokenAndUser: (state, action) => {
      const { token, user } = action.payload;
      state.token = token;
      state.isLogedin = true;
      state.currentUser = user;
    },

    logout: (state, action) => {
      removeuserData();
      state.token = "";
      state.isLogedin = false;
      state.currentUser = {
        role: "",
        _id: "",
        name: "",
        email: "",
        subscription: { active: false },

        avator: "",
      };
    },
    setFreshUser: (state, action) => {
      state.fresUser.email = action.payload.email;
      state.fresUser.password = action.payload.password;
    },
  },

  extraReducers: {
    [getCurrnetUser.fulfilled.toString()]: (state, action) => {
      const { success, user } = action.payload;
      if (success) {
        state.currentUser = user;
        state.isLogedin = true;

        updatecurrentuserdata(user);
      }
    },

    //singup reducert
    [singnupAction.fulfilled.toString()]: (state, action) => {
      const { success } = action.payload;
      if (success) {
      }
    },

    //login reducer
    [loginAction.fulfilled.toString()]: (state, action) => {
      const { success, token, user } = action.payload;
      if (success) {
        const newuser = {
          ...user,
        };
        state.token = token;
        state.currentUser = newuser;

        setcurrentuserandtoken(newuser, token);
        state.isLogedin = true;
      }
    },

    //uplod profile reducer
    [UploadProfileAction.fulfilled.toString()]: (state, action) => {
      const { success, user } = action.payload;

      if (success) {
        const newuser = {
          ...state.currentUser,
          avator: user.avator,
        };
        state.currentUser = newuser;
        updatecurrentuserdata(newuser);
      }
    },

    //userDataupdate
    [updateUserData.fulfilled.toString()]: (state, action) => {
      const { success, user } = action.payload;
      if (success) {
        state.currentUser = user;
        updatecurrentuserdata(user);
      }
    },

    [updatePassword.fulfilled.toString()]: (state, action) => {},
  },
});

export const { settokenAndUser, setToken, logout, setFreshUser } =
  userSlice.actions;

export default userSlice.reducer;
