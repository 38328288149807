import { createSlice } from '@reduxjs/toolkit';
import {
  getAllPlayer,
  getSpecifiPlayer,
  getTeams_name_and_id,
  CompareTwoPlayers,
  GetPlayerSkillsAvg,
} from './action';
import loginuser from '../../assets/images/loginuser.png';

const initialState = {
  playerArray: [],
  playersArray: [],
  simpleteamsarray: [],
  twoPlayers: [],
  skillsAvg: [],
  getTeams_name_and_id: [],

  initialValues: {
    name: '',
    surName: '',
    DOB: '',
    nationality: '',
    league: '',
    height: '',
    weight: '',
    skills: '',
    agency: '',
    match: '',
    foot: '',
    matchDates: '',
    matchName: '',
    matchResults: '',
    matchWeather: '',
    career: '',
    pic: loginuser,
    injuries: '',
    moreInformation: '',
    team: '',
    strength: '',
    weakness: '',
    vote: '',
    potentialvote: '',
    scouteanalyst: '',
    playstyledetail: '',
  },
};
const playerSlice = createSlice({
  name: 'playerSlice',
  initialState,
  reducers: {
    setInitialValues: (state, action) => {
      state.initialValues = { ...state.initialValues, ...action.payload };
    },
    setPicLink: (state, action) => {
      state.initialValues.pic = action.payload;
    },
    setTwoPlayersClear: (state, action) => {
      state.twoPlayers = [];
    },
    setInitialValuesClear: (state, action) => {
      state.initialValues = {
        name: '',
        surName: '',
        DOB: '',
        nationality: '',
        league: '',
        height: '',
        weight: '',
        skills: '',
        agency: '',
        match: '',
        position: '',
        foot: '',
        matchDates: '',
        matchName: '',
        matchResults: '',
        matchWeather: '',

        career: '',
        pic: loginuser,
        injuries: '',
        moreInformation: '',
        team: '',
        strength: '',
        weakness: '',
        vote: '',
        potentialvote: '',
        scouteanalyst: '',
        playstyledetail: '',
      };
    },
  },

  extraReducers: {
    [getAllPlayer.fulfilled.toString()]: (state, action) => {
      const { success, Players } = action.payload;
      if (success) {
        state.playersArray = Players;
      }
    },
    [getSpecifiPlayer.fulfilled.toString()]: (state, action) => {
      const { success, Players } = action.payload;
      if (success) {
        state.playerArray = { ...Players[0], team: Players[0].team[0] };
      }
    },
    [getTeams_name_and_id.fulfilled.toString()]: (state, action) => {
      const { success, team } = action.payload;
      if (success) {
        state.getTeams_name_and_id = team;
      }
    },

    [CompareTwoPlayers.fulfilled.toString()]: (state, action) => {
      const { success, Players } = action.payload;
      if (success) {
        state.twoPlayers = Players;
      }
    },

    [GetPlayerSkillsAvg.fulfilled.toString()]: (state, action) => {
      const { success, skillsAvg } = action.payload;
      if (success) {
        state.skillsAvg = skillsAvg;
      }
    },
  },
});

export const {
  setInitialValues,
  setInitialValuesClear,
  setPicLink,
  setTwoPlayersClear,
} = playerSlice.actions;

export default playerSlice.reducer;
