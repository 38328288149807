import { Formik } from "formik";
import React from "react";
import logo from "../../assets/images/logo.png";
import { resPassFormValidation } from "../../validation";
import Field from "../Field";

const ResetPass = (props) => {
  const { handleFormSubmit } = props;

  return (
    <>
      <section>
        <div className="container-fluid login-container reset-password-form">
          <div className="row align-items-center">
            <div className="col-md-6 text-center">
              <img className="img-fluid authlogo" src={logo} alt="Logo" />
            </div>

            <div className="col-md-6 login-form-2">
              <div>
                <h1>Reset Password</h1>
                <Formik
                  validationSchema={resPassFormValidation}
                  onSubmit={handleFormSubmit}
                  initialValues={{
                    newpassword: "",
                    passwordConform: "",
                  }}
                >
                  {(formik) => {
                    const { handleSubmit, errors, touched, isSubmitting } =
                      formik;
                    return (
                      <form onSubmit={handleSubmit}>
                        <fieldset className="new-file-input login-fields">
                          <legend className="file-input__label">
                            New Password
                          </legend>
                          <Field
                            name="newpassword"
                            type="password"
                            className="form-control file-input__input"
                            errors={errors}
                            touched={touched}
                          />
                        </fieldset>

                        <fieldset className="new-file-input login-fields">
                          <legend className="file-input__label">
                            Confirm Password
                          </legend>
                          <Field
                            name="passwordConform"
                            type="password"
                            className="form-control file-input__input"
                            errors={errors}
                            touched={touched}
                          />
                        </fieldset>

                        <div className="form-group">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btnprimary mt-2"
                          >
                            Reset Password
                          </button>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ResetPass;
