import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import PlayerView from "./Playerview";
import PlayerFooter from "./PlayerFooter";

const SinglePlayerComp = (props) => {
  const { SinglePlayer, handelEditClick, PlayerskillsAvg, handelDeleteClick } =
    props;

  return (
    <>
      <Header />
      <div className="body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 text-md-right text-center mb-md-0 mb-4 mt-5">
              <button
                className="btn btnprimary mr-3"
                onClick={() => handelEditClick()}
              >
                Edit Player
              </button>
              <button
                className="btn btndanger"
                onClick={() => handelDeleteClick()}
              >
                Delete
              </button>
            </div>

            <div className="col-md-12">
              <div>
                <PlayerView SinglePlayer={SinglePlayer} />
              </div>
            </div>
            <PlayerFooter
              PlayerskillsAvg={PlayerskillsAvg}
              SinglePlayer={SinglePlayer}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SinglePlayerComp;
