import React from "react";
import "react-circular-progressbar/dist/styles.css";
import graph2 from "../../assets/images/crossicon.png";
import graph3 from "../../assets/images/graphicon.png";
import graph4 from "../../assets/images/searchicon.png";
import graph1 from "../../assets/images/greencheck.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

const PlayerFooter = (props) => {
  const { SinglePlayer, PlayerskillsAvg } = props;
  if (SinglePlayer.length === 0 || PlayerskillsAvg.length === 0)
    return <div>Loading</div>;

  const array = [];
  const Physical = ["velocità", "esplosività", "fisicità", "resistenza"];

  const GoalkeperPhysicalInputFields = [
    "velocità",
    "esplosività",
    "fisicità",
    "resistenza",
    "salto",
  ];

  const MentalSkillsFields = [
    "intelligenza",
    "attenzione",
    "personalità",
    "comunicazione",
    "fantasia",
  ];

  const GoalkeperMentalSkillslabel = [
    "coraggio",
    "gestione",
    "personalità",
    "comunicazione",
  ];

  const TechnicalInputFields = [
    "senza palla",
    "marcatura",
    "anticipo",
    "capacità tattiche",
    "posizionamento",
    "passaggio",
    "dribbling",
    "finalizzazione",
    "gioco aereo",
    "transizione positiva",
    "transizione negativa",
    "calci piazzati a favore",
    "calci piazzato contro",
  ];

  const GoalkeperTechnicalInputFields = [
    "gioco con i piedi",
    "gioco con le mani",
    "parate",
    "posizionanento",
    "uscite alte",
    "uscite basse",
    "lettura traiettoria",
    "tempismo",
  ];

  for (let i = 0; i < PlayerskillsAvg.length; i++) {
    const ob = PlayerskillsAvg[i];
    if (ob._id === "poss") array[0] = ob.avg;
    if (ob._id === "non_poss") array[1] = ob.avg;
    if (ob._id === "men") array[2] = ob.avg;
  }
  return (
    <div>
      <section className="graphwrapper mt-0 pt-0">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4">
              <div className="blueshade play-style-graph">
                <p>Play style: {SinglePlayer.position || "NAN"}</p>
                <img
                  className="img-fluid"
                  src={
                    SinglePlayer.position &&
                    require(`../../assets/images/player-screen/${SinglePlayer.position}.jpg`)
                      .default
                  }
                  alt="topanalyst"
                />
              </div>
            </div>

            <div className="col-md-8">
              <div className="row progress-row-justify detail-circle-row">
                <div className="col-xl-3 col-lg-4 col-md-4">
                  <div className="blueshade mb-4 circlechart">
                    <div className="card-header">
                      <h5>qualità fisiche</h5>
                    </div>
                    <div className="circle-progressbar">
                      <CircularProgressbar
                        value={Number(array[0].toFixed(2)) * 10}
                        text={array[0].toFixed(2)}
                        strokeWidth={15}
                        styles={buildStyles({
                          textColor: "#19f495",
                          pathColor: "#19f495",
                          trailColor: "#0a3c43",
                        })}
                      />
                    </div>
                  </div>

                  <div className="blueshade">
                    <div className="clubdetail new-club-detail">
                      <h6>qualità fisiche</h6>
                      {SinglePlayer.skills[0].value.map((el, index) => {
                        if (
                          SinglePlayer.position === "POR-Moderno" ||
                          SinglePlayer.position === "POR-Classico"
                        ) {
                          return (
                            <h6 key={index}>
                              {`${GoalkeperPhysicalInputFields[index]}`}
                              <span className="float-right"> {el}</span>
                            </h6>
                          );
                        }
                        return (
                          <h6 key={index}>
                            {`${Physical[index]}`}
                            <span className="float-right"> {el}</span>
                          </h6>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-4 col-md-4">
                  <div className="blueshade mb-4 circlechart">
                    <div className="card-header">
                      <h5>qualità tecniche</h5>
                    </div>
                    <div className="circle-progressbar">
                      <CircularProgressbar
                        value={10 * Number(array[1].toFixed(2))}
                        text={array[1].toFixed(2)}
                        strokeWidth={15}
                        styles={buildStyles({
                          textColor: "#19f495",
                          pathColor: "#19f495",
                          trailColor: "#0a3c43",
                        })}
                      />
                    </div>
                  </div>
                  <div className="blueshade">
                    <div className="clubdetail new-club-detail">
                      <h6>qualità tecniche</h6>
                      {SinglePlayer.skills[1].value.map((el, index) => {
                        if (
                          SinglePlayer.position === "POR-Moderno" ||
                          SinglePlayer.position === "POR-Classico"
                        ) {
                          return (
                            <h6 key={index}>
                              {`${GoalkeperTechnicalInputFields[index]}`}
                              <span className="float-right"> {el}</span>
                            </h6>
                          );
                        }
                        return (
                          <h6 key={index}>
                            {`${TechnicalInputFields[index]}`}
                            <span className="float-right"> {el}</span>
                          </h6>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-4 col-md-4">
                  <div className="blueshade mb-4 circlechart">
                    <div className="card-header">
                      <h5>qualità mentali</h5>
                    </div>
                    <div className="circle-progressbar">
                      <CircularProgressbar
                        value={10 * Number(array[2].toFixed(2))}
                        text={array[2].toFixed(2)}
                        strokeWidth={15}
                        styles={buildStyles({
                          textColor: "#19f495",
                          pathColor: "#19f495",
                          trailColor: "#0a3c43",
                        })}
                      />
                    </div>
                  </div>
                  <div className="blueshade">
                    <div className="clubdetail new-club-detail">
                      <h6>qualità mentali</h6>
                      {SinglePlayer.skills[2].value.map((el, index) => {
                        if (
                          SinglePlayer.position === "POR-Moderno" ||
                          SinglePlayer.position === "POR-Classico"
                        ) {
                          return (
                            <h6 key={index}>
                              {`${GoalkeperMentalSkillslabel[index]}`}
                              <span className="float-right"> {el}</span>
                            </h6>
                          );
                        } else {
                          return (
                            <h6 key={index}>
                              {`${MentalSkillsFields[index]}`}
                              <span className="float-right"> {el}</span>
                            </h6>
                          );
                        }
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 my-4">
              <div className="card-header description-field-header">
                <h5>Play Style Detail</h5>
              </div>
              <div className="description-field">
                <textarea
                  className="detail-textarea form-control bg-darkblue"
                  rows={6}
                  readOnly
                >
                  {SinglePlayer.playstyledetail}
                </textarea>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="blueshade">
                <div className="card-header description-field-header">
                  <h5>Strengths</h5>
                  <img className="img-fluid" src={graph1} alt="topanalyst" />
                </div>
                <div className="description-field">
                  <textarea
                    className="detail-textarea form-control bg-darkblue"
                    rows={4}
                    readOnly
                  >
                    {SinglePlayer.strength}
                  </textarea>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="blueshade">
                <div className="card-header description-field-header">
                  <h5>Weaknesses</h5>
                  <img className="img-fluid" src={graph2} alt="topanalyst" />
                </div>
                <div className="description-field">
                  <textarea
                    className="detail-textarea form-control bg-darkblue"
                    rows={4}
                    readOnly
                  >
                    {SinglePlayer.weakness}
                  </textarea>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="blueshade">
                <div className="card-header description-field-header">
                  <h5>Vote</h5>
                  <img className="img-fluid" src={graph3} alt="topanalyst" />
                </div>
                <div className="description-field">
                  <textarea
                    className="detail-textarea form-control bg-darkblue"
                    rows={4}
                    readOnly
                  >
                    {SinglePlayer.vote}
                  </textarea>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="blueshade">
                <div className="card-header description-field-header">
                  <h5>Potential Vote</h5>
                  <img className="img-fluid" src={graph3} alt="" />
                </div>
                <div className="description-field">
                  <textarea
                    className="detail-textarea form-control bg-darkblue"
                    rows={4}
                    readOnly
                  >
                    {SinglePlayer.potentialvote}
                  </textarea>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="blueshade">
                <div className="card-header description-field-header">
                  <h5>Scout/Analyst</h5>
                  <img className="img-fluid" src={graph4} alt="" />
                </div>
                <div className="description-field">
                  <textarea
                    className="detail-textarea form-control bg-darkblue"
                    rows={4}
                    readOnly
                  >
                    {SinglePlayer.scouteanalyst}
                  </textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PlayerFooter;
