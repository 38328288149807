import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { LoginFormValidation } from "../../validation";
import { Formik } from "formik";
import Field from "../Field";

const Login = (props) => {
  const { handleFormSubmit, fresUser } = props;

  const [initalValue, setinitalValue] = useState({ email: "", password: "" });

  useEffect(() => {
    setinitalValue({ email: fresUser.email, password: fresUser.password });
  }, [fresUser]);

  return (
    <>
      <section>
        <div className="container-fluid login-container">
          <div className="row align-items-center">
            <div className="col-md-6 text-center">
              <img className="img-fluid authlogo" src={logo} alt="" />
            </div>
            <div className="col-md-6 login-form-2 d-md-flex align-items-center">
              <div className="w-100">
                <h1>Login</h1>
                <Formik
                  validationSchema={LoginFormValidation}
                  onSubmit={handleFormSubmit}
                  initialValues={initalValue}
                  enableReinitialize={true}
                >
                  {(formik) => {
                    const { handleSubmit, errors, touched, isSubmitting } =
                      formik;

                    return (
                      <form onSubmit={handleSubmit}>
                        {/* <h2 className="mb-25">Login In</h2> */}
                        <p className="my-4">
                          Don't have an account?{" "}
                          <Link to="/signup">Sign Up</Link>
                        </p>

                        <fieldset className="new-file-input login-fields">
                          <legend className="file-input__label">
                            Your email
                          </legend>
                          <Field
                            name="email"
                            type="text"
                            className="form-control file-input__input"
                            errors={errors}
                            touched={touched}
                          />
                        </fieldset>

                        <fieldset className="new-file-input login-fields">
                          <legend className="file-input__label">
                            Your Password
                          </legend>
                          <Field
                            name="password"
                            type="password"
                            className="form-control file-input__input"
                            errors={errors}
                            touched={touched}
                          />
                        </fieldset>

                        <div className="form-group">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btnprimary"
                          >
                            Login
                          </button>
                        </div>
                        <p className="mb-45">
                          <Link to="/forget-password"> Forget Password?</Link>
                        </p>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Login;
