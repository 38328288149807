import React, { useEffect, useState } from "react";
import * as Action from "../../action";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import uploadPicture from "../../utils/uploadPic";
import { useDispatch, useSelector } from "react-redux";
import { PlayerArrayBulder } from "../../utils/Skills";
import { useHistory, useParams } from "react-router-dom";
import loginuser from "../../assets/images/dummyuser.png";
import { setInitialValues, setInitialValuesClear } from "./slice";
import AddNewPlayerComp from "../../components/Player/AddNewPlayer";
import { selectinitialValues, selectTeams_name_and_id } from "./selector";

const AddNewPlayer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [EditMode, setEditMode] = useState(false);
  const prams = useParams();
  const initialValues = useSelector(selectinitialValues);
  const Teams_name_and_id = useSelector(selectTeams_name_and_id);
  // edit=true&id=613715bc849da93b5ce5d684/
  useEffect(() => {
    if (!!prams.id) {
      const id = prams.id.split("&");

      if (id[0] === "edit=true") {
        const usetId = id[1].split("=")[1];
        setEditMode(usetId);
        dispatch(Action.getTeams_name_and_id());
      }
    } else {
      dispatch(setInitialValuesClear({}));
      dispatch(setInitialValues({ pic: loginuser }));
      dispatch(Action.getTeams_name_and_id());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitFormhandler = async (
    FormValues,
    { resetForm, setSubmitting }
  ) => {
    const match = {
      matchName: FormValues.matchName,
      matchResults: FormValues.matchResults,
      matchDates: FormValues.matchDates,
      matchWeather: FormValues.matchWeather,
    };
    const newFormData = { ...FormValues, match };
    const updateFormData = PlayerArrayBulder(newFormData);

    let action;
    if (!EditMode) {
      if (updateFormData.team === "") {
        delete updateFormData.team;
        action = await dispatch(Action.createNewPlayer(updateFormData));
      } else {
        action = await dispatch(Action.createNewPlayer(updateFormData));
      }
    } else {
      if (updateFormData.team === "") {
        delete updateFormData.team;
        action = await dispatch(
          Action.editPlayer({ updateFormData, id: EditMode })
        );
      } else {
        action = await dispatch(
          Action.editPlayer({ updateFormData, id: EditMode })
        );
      }
    }
    resetForm();
    setSubmitting(false);

    if (action.payload.success) {
      history.push("/players");
      FormValues = {};
    }
  };
  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    let fromdata = new FormData();
    fromdata.append("image", file);
    await uploadPicture(fromdata);
  };

  useEffect(() => {}, []);

  return (
    <div className="body">
      <Header />
      <AddNewPlayerComp
        handleImageUpload={handleImageUpload}
        initialValues={initialValues}
        Teams_name_and_id={Teams_name_and_id}
        handleFormSubmit={submitFormhandler}
      />
      <Footer />
    </div>
  );
};

export default AddNewPlayer;
