import { Formik } from "formik";
import React from "react";
import { UplodnewpasswordFormValidation } from "../../validation";
import Header from "../Header";
import Field from "../Field";
import ProfileLeftSideNav from "../ProfileLeftSideNav";
const ChangePass = (props) => {
  const { handleFormSubmit, currentUser } = props;

  const namess = `${currentUser.firstName} ${currentUser.lastName}`;

  return (
    <>
      <Header />
      <section className="editprofilesec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="profiler">
                <h2>{namess}</h2>
              </div>
            </div>

            <div className="col-md-3 br-2">
              <ProfileLeftSideNav />
            </div>

            <div className="col-md-9">
              <div className="paymentform bgform">
                <Formik
                  validationSchema={UplodnewpasswordFormValidation}
                  onSubmit={handleFormSubmit}
                  initialValues={{
                    oddpassword: "",
                    newpassword: "",
                    newpasswordConform: "",
                  }}
                >
                  {(formik) => {
                    const { handleSubmit, errors, touched, isSubmitting } =
                      formik;
                    return (
                      <form onSubmit={handleSubmit} className="inputcolor">
                        <fieldset className="new-file-input login-fields">
                          <legend className="file-input__label">
                            Old Password
                          </legend>
                          <Field
                            name="oddpassword"
                            type="password"
                            errors={errors}
                            touched={touched}
                            className="form-control file-input__input"
                          />
                        </fieldset>

                        <fieldset className="new-file-input login-fields">
                          <legend className="file-input__label">
                            New Password
                          </legend>
                          <Field
                            name="newpassword"
                            type="password"
                            errors={errors}
                            touched={touched}
                            className="form-control file-input__input"
                          />
                        </fieldset>

                        <fieldset className="new-file-input login-fields">
                          <legend className="file-input__label">
                            Confirm Password
                          </legend>
                          <Field
                            name="newpasswordConform"
                            type="password"
                            errors={errors}
                            touched={touched}
                            className="form-control file-input__input"
                          />
                        </fieldset>

                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="btn btnprimary d-block ml-auto"
                        >
                          Change
                        </button>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ChangePass;
