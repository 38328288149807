import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducer";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory({
  basename: "/",
  hashType: "noslash",
});

export const store = configureStore({
  reducer: rootReducer(history),

  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ serializableCheck: false });
  },
});

