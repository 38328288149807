import { combineReducers } from "@reduxjs/toolkit";
import { connectRouter } from "connected-react-router";

import loader from "./container/loader/loader.slice";
import userReducer from "./container/User/user.slice";
import playerReducer from "./container/player/slice";
import TeamReducer from "./container/Team/team.slice";
import packagesSlice from "./container/packges/packages.slice";

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    playerReducer,
    userReducer,
    loader,
    TeamReducer,
    packagesSlice,
  });

export default rootReducer;
