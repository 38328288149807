import { createDraftSafeSelector } from "@reduxjs/toolkit";

const selectDomain = (state) => state.TeamReducer;

export const selectPlayerArray = createDraftSafeSelector(
  selectDomain,
  (playerReducer) => playerReducer.playersArray
);

export const selectSchoteArray = createDraftSafeSelector(
  selectDomain,
  (playerReducer) => playerReducer.schotesArray
);

export const selectTeamsArray = createDraftSafeSelector(
  selectDomain,
  (playerReducer) => playerReducer.TeamsArray
);

export const selectSingleTeam = createDraftSafeSelector(
  selectDomain,
  (playerReducer) => playerReducer.SingleTeam
);

export const selectinitialValues = createDraftSafeSelector(
  selectDomain,
  (playerReducer) => playerReducer.initialValues
);

export const selecttwoTeamsValues = createDraftSafeSelector(
  selectDomain,
  (playerReducer) => playerReducer.twoTeams
);


export const selectskillsAvg = createDraftSafeSelector(
  selectDomain,
  (playerReducer) => playerReducer.skillsAvg
);


