import React from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectCurrentUser,
  selectisLogedin,
} from "../../container/User/user.select";

export const ProtectedPage = (ComposedComponent) => {
  const isLogedin = useSelector(selectisLogedin);
  class Authentication extends React.PureComponent {
    render() {
      if (isLogedin) {
        return <ComposedComponent {...this.props} />;
      } else {
        return <Redirect to="/" />;
      }
    }
  }
  return Authentication;
};

export const Authenciated = (ComposedComponent) => {
  const isLogedin = useSelector(selectisLogedin);
  class Authentication extends React.PureComponent {
    render() {
      if (isLogedin) {
        return <Redirect {...this.props} to="/teams" />;
      } else {
        return <ComposedComponent {...this.props} />;
      }
    }
  }

  return Authentication;
};

export function SubScription(ComposedComponent) {
  const user = useSelector(selectCurrentUser);
  const isLogedin = useSelector(selectisLogedin);

  return function (props) {
    if (user?.subscription?.active && isLogedin) {
      return <ComposedComponent {...props} />;
    } else if (!isLogedin) {
      return <Redirect to="/" />;
    } else {
      return <Redirect to="/packages" />;
    }
  };
}
