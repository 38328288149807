import React, { Suspense, memo } from "react";
import { Switch, Route } from "react-router-dom";
import SignUp from "../container/User/Singup";
import Login from "../container/User/Login";
import ForgetPass from "../container/User/ForgetPass";
import ResetPassword from "../container/User/ResetPass";

import PlayerComprasion from "../container/player/playerComp";
import Players from "../container/player";

import AddNewPlayer from "../container/player/AddNewPlayer";
import SinglePlayer from "../container/player/playerEditView";

import TeamComprasion from "../container/Team/teamComp";
import Teams from "../container/Team";
import AddNewTeam from "../container/Team/AddNewTeam";
import SingleTeam from "../container/Team/teamEditView";

import CustomerEditProfile from "../container/User/EditProfile";
import ChangePass from "../container/User/changePass";
import Packages from "../container/packges/index";
import Endsubscription from "../container/Endsubscription";
import Confirmations from "../components/confirmations";

import { Authenciated, ProtectedPage, SubScription } from "./privateRoutes";

const NotFoundPage = React.lazy(() => import("../components/404"));
const Routes = memo(() => (
  <Suspense fallback="Loading......">
    <Switch>
      <Route exact path="/" component={Authenciated(Login)} />
      <Route exact path="/signup" component={Authenciated(SignUp)} />
      <Route exact path="/forget-password" component={ForgetPass} />
      <Route exact path="/reset-password/:token" component={ResetPassword} />
      <Route exact path="/packages" component={Packages} />
      <Route exact path="/end-subscription" component={Endsubscription} />
      <Route exact path="/players" component={SubScription(Players)} />
      <Route
        exact
        path="/PlayerComprasion"
        component={SubScription(PlayerComprasion)}
      />
      <Route
        exact
        path="/player/addNew"
        component={SubScription(AddNewPlayer)}
      />
      <Route exact path="/player/:id" component={SubScription(SinglePlayer)} />
      <Route
        exact
        path="/player/:id/edit"
        component={SubScription(AddNewPlayer)}
      />
      <Route exact path="/teams" component={SubScription(Teams)} />
      <Route
        exact
        path="/teamComprasion"
        component={SubScription(TeamComprasion)}
      />
      <Route exact path="/team/addNew" component={SubScription(AddNewTeam)} />
      <Route exact path="/team/:id" component={SubScription(SingleTeam)} />
      <Route exact path="/subsc/:id" component={Confirmations} />
      <Route exact path="/teams/goalkepers" component={SubScription(Players)} />
      <Route exact path="/team/:id/edit" component={SubScription(AddNewTeam)} />
      <Route
        exact
        path="/edit-profile"
        component={ProtectedPage(CustomerEditProfile)}
      />
      <Route
        exact
        path="/change-password"
        component={ProtectedPage(ChangePass)}
      />
      <Route component={NotFoundPage} />
    </Switch>
  </Suspense>
));

export default Routes;
