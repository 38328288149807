import React from "react";
import App from "./App";
import axios from "axios";
import { store } from "./store";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { getcurrentuser } from "./utils";
import ScrollToTop from "./utils/scroleToTop";
import toastBuilder from "./utils/toastBuilder";
import { BrowserRouter } from "react-router-dom";
import { setloader } from "./container/loader/loader.slice";
import { settokenAndUser } from "./container/User/user.slice";
import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";
import { SERVER_URL } from "./config/constants";

axios.interceptors.request.use(
  (config) => {
    const token = store.getState().userReducer.token;
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      };
    }

    config.url = SERVER_URL + config.url;
    store.dispatch(setloader(true));
    return config;
  },

  (error) => {
    store.dispatch(setloader(false));
    if (error.request) {
      toastBuilder("error", "something went wrong while makeing request");
    }
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    const { data } = response;
    store.dispatch(setloader(false));

    if (response.data.message) {
      toastBuilder("success", response.data.message);
    }
    const newData = { success: data.success, ...data.data };
    return newData;
  },
  (error) => {
    if (error.message === "Network Error") {
      store.dispatch(setloader(false));
      return toastBuilder("error", error.message);
    }

    const { data } = error.response;
    const { response } = error;

    if (error.response) {
      if (response.data.message) toastBuilder("error", response.data.message);
    } else if (error.request) {
      return toastBuilder("error", error.message);
    } else {
      return toastBuilder("error", error.message);
    }
    store.dispatch(setloader(false));
    return data;
  }
);

const token = window.localStorage.getItem("token");
if (token) {
  const user = getcurrentuser();
  if (user) {
    store.dispatch(settokenAndUser({ token, user }));
  }
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <ScrollToTop>
          <App />
        </ScrollToTop>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

export { axios };
