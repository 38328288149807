import React, { useState } from "react";
import PackagesComponent from "../../components/Packages";
import * as Action from "../../action";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../User/user.select";

const Packages = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const [couponInput, setCouponInput] = useState();

  const handleMonthallySubscription = async () => {
    const data = {
      success_url: `${process.env.REACT_APP_URL}subsc/${user._id}`,
      cancel_url: `${process.env.REACT_APP_URL}subsc/${user._id}`,
      description: "this is best thig to buy in this price",
    };
    const { payload } = await dispatch(
      Action.monthallySubscription({ id: user._id, data })
    );
    if (payload.success) {
      window.open(payload.session.url, "_blank"); //to open new page
    }
  };
  const handleLifetimeSubscription = async () => {
    const data = {
      success_url: `${process.env.REACT_APP_URL}subsc/${user._id}`,
      cancel_url: `${process.env.REACT_APP_URL}subsc/${user._id}`,
      description: "pay once use for life time",
      couponCode: couponInput,
    };
    const { payload } = await dispatch(
      Action.lifeTimeSubscription({ id: user._id, data })
    );
    if (payload.success) {
      window.open(payload.session.url, "_blank"); //to open new page
    }
  };
  return (
    <PackagesComponent
      handleMonthallySubscription={handleMonthallySubscription}
      handleLifetimeSubscription={handleLifetimeSubscription}
      setCouponInput={setCouponInput}
      couponInput={couponInput}
    />
  );
};

export default Packages;
