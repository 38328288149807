import React from "react";
import * as Action from "../../action";
import { useDispatch } from "react-redux";
import { setFreshUser } from "./user.slice";
import { useHistory } from "react-router-dom";
import SignUpForm from "../../components/SignUp";

const UserSingup = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  };

  const handleFormSubmit = async (formValues, { resetForm, setSubmitting }) => {
    const action = await dispatch(Action.singnupAction(formValues));
    if (action.payload.success) {
      resetForm();
      history.push("/");
      dispatch(
        setFreshUser({
          email: formValues.email,
          password: formValues.password,
        })
      );
    }

    setSubmitting(false);
  };

  return (
    <SignUpForm
      initialValues={initialValues}
      handleFormSubmit={handleFormSubmit}
    />
  );
};

export default UserSingup;
