import "react-circular-progressbar/dist/styles.css";
import "react-circular-progressbar/dist/styles.css";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { Field, Formik } from "formik";
import {
  addNewPlayerPersonalData,
  addNewPlayerPersonalDataModeiedWithGoalkeper,
} from "../../validation";

import CustomField from "../Field";
import React from "react";
import graph1 from "../../assets/images/greencheck.png";
import graph2 from "../../assets/images/crossicon.png";
import graph3 from "../../assets/images/graphicon.png";
import graph4 from "../../assets/images/searchicon.png";
import newPlayerIcon from "../../assets/images/icons/new-player-icon.png";
import uploadPicture from "../../utils/uploadPic";

const Player = (props) => {
  const { handleFormSubmit, initialValues, Teams_name_and_id } = props;
  const newInitalvalues = {
    poss0: "",
    poss1: "",
    poss2: "",
    poss3: "",

    non_poss0: "",
    non_poss1: "",
    non_poss2: "",
    non_poss3: "",
    non_poss4: "",
    non_poss5: "",
    non_poss6: "",
    non_poss7: "",
    non_poss8: "",
    non_poss9: "",
    non_poss10: "",
    non_poss11: "",
    non_poss12: "",

    men0: "",
    men1: "",
    men2: "",
    men3: "",
    men4: "",

    ...initialValues,
  };

  const GoalKeper = {
    poss0: "",
    poss1: "",
    poss2: "",
    poss3: "",
    poss4: "",

    non_poss0: "",
    non_poss1: "",
    non_poss2: "",
    non_poss3: "",
    non_poss4: "",
    non_poss5: "",
    non_poss6: "",
    non_poss7: "",

    men0: "",
    men1: "",
    men2: "",
    men3: "",

    ...initialValues,
  };

  const playerInfromationInputFields = [
    { name: "name", type: "text", placeholder: "enter name" },
    { name: "surName", type: "text", placeholder: "enter surname" },
    { name: "DOB", type: "date", placeholder: "enter date of birth" },
    {
      name: "nationality",
      type: "text",
      placeholder: "enter nationality",
    },
    { name: "league", type: "text", placeholder: "enter league" },
    { name: "height", type: "text", placeholder: "enter height" },
    { name: "weight", type: "text", placeholder: "enter weight" },
    { name: "agency", type: "text", placeholder: "enter agency" },
    { name: "matchName", type: "text", placeholder: "enter match" },
    {
      name: "matchDates",
      type: "text",
      placeholder: "enter match dates",
    },
    {
      name: "matchWeather",
      type: "text",
      placeholder: "enter match weather",
    },
    {
      name: "matchResults",
      type: "text",
      placeholder: "enter match results",
    },
    { name: "foot", type: "text", placeholder: "enter foot" },
    { name: "career", type: "text", placeholder: "enter career" },
    {
      name: "moreInformation",
      type: "text",
      placeholder: "enter more information",
    },
    { name: "injuries", type: "text", placeholder: "enter injuries" },
  ];

  const votesGraphData = [
    {
      name: "strength",
      heading: "strength",
      type: "textarea",
      placeholder: "Enter here...",
      hidelable: false,
    },
    {
      name: "weakness",
      heading: "weakness",
      type: "textarea",
      placeholder: "Enter here...",
      hidelable: false,
    },
    {
      name: "vote",
      heading: "vote",
      type: "textarea",
      placeholder: "Enter here...",
      hidelable: false,
    },
    {
      name: "potentialvote",
      heading: "potential vote",
      type: "textarea",
      placeholder: "Enter here...",
      hidelable: false,
    },
    {
      name: "scouteanalyst",
      heading: "scout analyst",
      type: "textarea",
      placeholder: "Enter here...",
      hidelable: false,
    },
  ];

  const Physical = [
    {
      name: "poss0",
      type: "number",
      placeholder: "velocità",
      hidelable: false,
    },
    {
      name: "poss1",
      type: "number",
      placeholder: "esplosività",
      hidelable: false,
    },
    {
      name: "poss2",
      type: "number",
      placeholder: "fisicità",
      hidelable: false,
    },
    {
      name: "poss3",
      type: "number",
      placeholder: "resistenza",
      hidelable: false,
    },
  ];

  const GoalkeperPhysical = [
    {
      name: "poss0",
      type: "number",
      placeholder: "velocità",
      hidelable: false,
    },
    {
      name: "poss1",
      type: "number",
      placeholder: "esplosività",
      hidelable: false,
    },
    {
      name: "poss2",
      type: "number",
      placeholder: "fisicità",
      hidelable: false,
    },
    {
      name: "poss3",
      type: "number",
      placeholder: "resistenza",
      hidelable: false,
    },
    {
      name: "poss4",
      type: "number",
      placeholder: "salto",
      hidelable: false,
    },
  ];

  const TechnicalInputFields = [
    {
      name: "non_poss0",
      type: "number",
      placeholder: "senza palla",
      hidelable: false,
    },
    {
      name: "non_poss1",
      type: "number",
      placeholder: "marcatura",
      hidelable: false,
    },
    {
      name: "non_poss2",
      type: "number",
      placeholder: "anticipo",
      hidelable: false,
    },
    {
      name: "non_poss3",
      type: "number",
      placeholder: "capacità tattiche",
      hidelable: false,
    },
    {
      name: "non_poss4",
      type: "number",
      placeholder: "posizionamento",
      hidelable: false,
    },
    {
      name: "non_poss5",
      type: "number",
      placeholder: "passaggio",
      hidelable: false,
    },
  ];

  const TechnicalInputFieldsRemaining = [
    {
      name: "non_poss6",
      type: "number",
      placeholder: "dribbling",
      hidelable: false,
    },
    {
      name: "non_poss7",
      type: "number",
      placeholder: "finalizzazione",
      hidelable: false,
    },
    {
      name: "non_poss8",
      type: "number",
      placeholder: "gioco aereo",
      hidelable: false,
    },
    {
      name: "non_poss9",
      type: "number",
      placeholder: "transizione positiva",
      hidelable: false,
    },
    {
      name: "non_poss10",
      type: "number",
      placeholder: "transizione negativa",
      hidelable: false,
    },
    {
      name: "non_poss11",
      type: "number",
      placeholder: "calci piazzati a favore",
      hidelable: false,
    },
    {
      name: "non_poss12",
      type: "number",
      placeholder: "calci piazzato contro",
      hidelable: false,
    },
  ];

  const GoalkeperTechnicalInputFields = [
    {
      name: "non_poss0",
      type: "number",
      placeholder: "gioco con i piedi",
      hidelable: false,
    },
    {
      name: "non_poss1",
      type: "number",
      placeholder: "gioco con le mani",
      hidelable: false,
    },
    {
      name: "non_poss2",
      type: "number",
      placeholder: "parate",
      hidelable: false,
    },
    {
      name: "non_poss3",
      type: "number",
      placeholder: "posizionanento",
      hidelable: false,
    },
    {
      name: "non_poss4",
      type: "number",
      placeholder: "uscite alte",
      hidelable: false,
    },
    {
      name: "non_poss5",
      type: "number",
      placeholder: "uscite basse",
      hidelable: false,
    },
    {
      name: "non_poss6",
      type: "number",
      placeholder: "lettura traiettoria",
      hidelable: false,
    },
    {
      name: "non_poss7",
      type: "number",
      placeholder: "tempismo",
      hidelable: false,
    },
  ];

  const MentalSkillsFields = [
    {
      name: "men0",
      type: "number",
      placeholder: "intelligenza",
      hidelable: false,
    },
    {
      name: "men1",
      type: "number",
      placeholder: "attenzione",
      hidelable: false,
    },
    {
      name: "men2",
      type: "number",
      placeholder: "personalità",
      hidelable: false,
    },
    {
      name: "men3",
      type: "number",
      placeholder: "comunicazione",
      hidelable: false,
    },
    {
      name: "men4",
      type: "number",
      placeholder: "fantasia",
      hidelable: false,
    },
  ];

  const GoalkeperMentalSkillslabel = [
    {
      name: "men0",
      type: "number",
      placeholder: "coraggio",
      hidelable: false,
    },
    {
      name: "men1",
      type: "number",
      placeholder: "gestione",
      hidelable: false,
    },
    {
      name: "men2",
      type: "number",
      placeholder: "personalità",
      hidelable: false,
    },
    {
      name: "men3",
      type: "number",
      placeholder: "comunicazione",
      hidelable: false,
    },
  ];

  const [playStyle, setPlayStyle] = React.useState(null);

  return (
    <div>
      <section className="clubwrapper">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="blueshade d-flex minh-100">
                <Formik
                  validationSchema={addNewPlayerPersonalData}
                  initialValues={newInitalvalues}
                  onSubmit={handleFormSubmit}
                  enableReinitialize={true}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    values,
                    touched,
                    isValid,
                    errors,
                    dirty,
                  }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="analysis-header">
                            <div className="choose-file-top">
                              <div className="custom-file">
                                <div
                                  className="select_file"
                                  for="validatedCustomFile"
                                >
                                  <img
                                    className="d-block mx-auto"
                                    src={values.pic || initialValues.pic}
                                    alt="topanalyst"
                                    width="75"
                                    height="75"
                                    style={{
                                      borderRadius: "50%",
                                      objectFit: "cover",
                                    }}
                                    for="validatedCustomFile"
                                  />
                                  <div className="file-select-btn">
                                    <span>Nessun file selezionato</span>
                                    <label className="custom-file-upload">
                                      <input
                                        name="pic"
                                        type="file"
                                        className="text-white"
                                        id="validatedCustomFile"
                                        onChange={async (event) => {
                                          const file = event.target.files[0];
                                          let fromdata = new FormData();
                                          fromdata.append("image", file);
                                          const useLink = await uploadPicture(
                                            fromdata
                                          );
                                          setFieldValue(
                                            "pic",
                                            useLink.imageUrl
                                          );
                                        }}
                                      />
                                      Select File
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="add-new-player">
                              <img src={newPlayerIcon} alt="" />
                              <h2>Add New Player</h2>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <fieldset className="new-file-input">
                              <legend className="file-input__label">
                                Select Team
                              </legend>
                              <Field
                                name="team"
                                component="select"
                                placeholder="slect coach"
                                className="form-control file-input__input"
                              >
                                <option value="">No Team Select</option>

                                {Array.isArray(Teams_name_and_id) &&
                                Teams_name_and_id.length > 0 ? (
                                  Teams_name_and_id.map((team, index) => {
                                    return (
                                      <option key={index} value={team._id}>
                                        {team.teamName}
                                      </option>
                                    );
                                  })
                                ) : (
                                  <div>No Team Found</div>
                                )}
                              </Field>
                            </fieldset>
                          </div>

                          <div className="col-md-3">
                            <fieldset className="new-file-input">
                              <legend className="file-input__label">
                                Play Style
                              </legend>
                              <Field
                                name="position"
                                component="select"
                                placeholder="slect position"
                                className="form-control file-input__input"
                              >
                                {!initialValues.position && (
                                  <option value="" disabled></option>
                                )}

                                {[
                                  "POR-Classico",
                                  "POR-Moderno",
                                  "DC-Tecnico",
                                  "DC-Fisico",
                                  "TER-DX-Difensivo",
                                  "TER-SX-Difensivo",
                                  "TER-DX-Offensivo",
                                  "TER-SX-Offensivo",
                                  "M-Fisico",
                                  "M-Tecnico",
                                  "CC-Fisico",
                                  "CC-Tecnico",
                                  "TC",
                                  "CL-SX",
                                  "CL-DX",
                                  "ALA-SX",
                                  "ALA-DX",
                                  "PC-Fissa",
                                  "PC-Mobile",
                                  "Seconda-Punta",
                                  "Falso-Nueve",
                                ].map((value, index) => {
                                  return (
                                    <option key={index} value={value}>
                                      {value}
                                    </option>
                                  );
                                })}
                              </Field>
                              {errors["position"] && touched["position"] && (
                                <span className="text-danger">
                                  {errors["position"]}
                                </span>
                              )}
                            </fieldset>
                          </div>

                          {playerInfromationInputFields.map(
                            ({ name, type, placeholder }, index) => {
                              return (
                                <div className="col-md-3" key={index}>
                                  <fieldset className="new-file-input">
                                    <legend className="file-input__label">
                                      {placeholder}
                                    </legend>
                                    <CustomField
                                      name={name}
                                      type={type}
                                      className="file-input__input"
                                      errors={errors}
                                      touched={touched}
                                    />
                                  </fieldset>
                                </div>
                              );
                            }
                          )}
                        </div>

                        <div className="graphwrapper">
                          <div className="container-fluid">
                            <div className="row">
                              <div className="col-md-4">
                                <div className="blueshade play-style-graph">
                                  <p>Play Style: {values.position}</p>
                                  <img
                                    className="img-fluid"
                                    src={
                                      values.position &&
                                      require(`../../assets/images/player-screen/${values.position}.jpg`)
                                        .default
                                    }
                                    alt="Play Style Graph"
                                  />
                                </div>
                              </div>

                              <div className="col-md-8">
                                <div className="row mb-3 progress-row-justify">
                                  <div className="col-xl-3 col-lg-4 col-md-4">
                                    <div className="blueshade circlechart">
                                      <div className="card-header">
                                        <h5>qualità fisiche</h5>
                                      </div>
                                      <div className="circle-progressbar">
                                        <CircularProgressbar
                                          value=""
                                          text=""
                                          strokeWidth={15}
                                          styles={buildStyles({
                                            textColor: "#19f495",
                                            pathColor: "#19f495",
                                            trailColor: "#0a3c43",
                                          })}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-xl-3 col-lg-4 col-md-4">
                                    <div className="blueshade circlechart">
                                      <div className="card-header">
                                        <h5>qualità tecniche</h5>
                                      </div>
                                      <div className="circle-progressbar">
                                        <CircularProgressbar
                                          value=""
                                          text=""
                                          strokeWidth={15}
                                          styles={buildStyles({
                                            textColor: "#19f495",
                                            pathColor: "#19f495",
                                            trailColor: "#0a3c43",
                                          })}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-xl-3 col-lg-4 col-md-4">
                                    <div className="blueshade circlechart">
                                      <div className="card-header">
                                        <h5>qualità mentali</h5>
                                      </div>
                                      <div className="circle-progressbar">
                                        <CircularProgressbar
                                          value=""
                                          text=""
                                          strokeWidth={15}
                                          styles={buildStyles({
                                            textColor: "#19f495",
                                            pathColor: "#19f495",
                                            trailColor: "#0a3c43",
                                          })}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-lg-3 col-md-6 col-12">
                                    <div className="blueshade d-flex">
                                      <div className="clubdetail new-club-detail">
                                        <h6>qualità fisiche</h6>
                                        {Physical.map(
                                          (
                                            {
                                              name,
                                              type,
                                              placeholder,
                                              hidelable,
                                            },
                                            index
                                          ) => {
                                            return (
                                              <div key={index}>
                                                <fieldset className="new-file-input">
                                                  <legend className="file-input__label">
                                                    {placeholder}
                                                  </legend>
                                                  <CustomField
                                                    name={name}
                                                    hidelable={hidelable}
                                                    type={type}
                                                    className="file-input__input"
                                                    errors={errors}
                                                    touched={touched}
                                                  />
                                                </fieldset>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-3 col-md-6 col-12">
                                    <div className="blueshade d-flex minh-100">
                                      <div className="clubdetail new-club-detail">
                                        <h6>qualità tecniche</h6>
                                        {TechnicalInputFields.map(
                                          (
                                            {
                                              name,
                                              type,
                                              placeholder,
                                              hidelable,
                                            },
                                            index
                                          ) => {
                                            return (
                                              <div key={index}>
                                                <fieldset className="new-file-input">
                                                  <legend className="file-input__label">
                                                    {placeholder}
                                                  </legend>
                                                  <CustomField
                                                    name={name}
                                                    hidelable={hidelable}
                                                    type={type}
                                                    className="file-input__input"
                                                    errors={errors}
                                                    touched={touched}
                                                  />
                                                </fieldset>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-3 col-md-6 col-12">
                                    <div className="blueshade d-flex minh-100">
                                      <div className="clubdetail new-club-detail">
                                        {TechnicalInputFieldsRemaining.map(
                                          (
                                            {
                                              name,
                                              type,
                                              placeholder,
                                              hidelable,
                                            },
                                            index
                                          ) => {
                                            return (
                                              <div key={index}>
                                                <fieldset className="new-file-input">
                                                  <legend className="file-input__label">
                                                    {placeholder}
                                                  </legend>
                                                  <CustomField
                                                    name={name}
                                                    hidelable={hidelable}
                                                    type={type}
                                                    className="file-input__input"
                                                    errors={errors}
                                                    touched={touched}
                                                  />
                                                </fieldset>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-3 col-md-6 col-12">
                                    <div className="blueshade d-flex minh-100">
                                      <div className="clubdetail new-club-detail">
                                        <h6>qualità mentali</h6>
                                        {MentalSkillsFields.map(
                                          (
                                            {
                                              name,
                                              type,
                                              placeholder,
                                              hidelable,
                                            },
                                            index
                                          ) => {
                                            return (
                                              <div key={index}>
                                                <fieldset className="new-file-input">
                                                  <legend className="file-input__label">
                                                    {placeholder}
                                                  </legend>
                                                  <CustomField
                                                    name={name}
                                                    hidelable={hidelable}
                                                    type={type}
                                                    className="file-input__input"
                                                    errors={errors}
                                                    touched={touched}
                                                  />
                                                </fieldset>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12 mt-3 mb-4">
                                <div className="card-header description-field-header">
                                  <h5>Play Style</h5>
                                </div>
                                <div className="description-field">
                                  <CustomField
                                    name="playstyledetail"
                                    type="textarea"
                                    hidelable={false}
                                    value={values.playstyledetail}
                                    handleChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter description..."
                                    className="form-control"
                                    errors={errors}
                                    row="6"
                                    touched={touched}
                                  />
                                </div>
                              </div>

                              {votesGraphData.map((obj, index) => {
                                return (
                                  <div
                                    className="col-lg-4 col-md-6"
                                    key={index}
                                  >
                                    <div className="blueshade">
                                      <div className="card-header description-field-header">
                                        <h5>{obj.heading}</h5>
                                        <img
                                          className="img-fluid"
                                          src={
                                            index === 0
                                              ? graph1
                                              : index === 1
                                              ? graph2
                                              : index === 2
                                              ? graph3
                                              : index === 3
                                              ? graph3
                                              : index === 4
                                              ? graph4
                                              : ""
                                          }
                                          alt=""
                                        />
                                      </div>
                                      <div className="description-field">
                                        <CustomField
                                          name={obj.name}
                                          type={obj.type}
                                          hidelable={obj.hidelable}
                                          value={values[obj.name]}
                                          handleChange={handleChange}
                                          placeholder={obj.placeholder}
                                          className="form-control"
                                          errors={errors}
                                          touched={touched}
                                          row="4"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 text-right">
                          <button type="submit" className="btn btnprimary">
                            Save
                          </button>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Player;
