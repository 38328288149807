import React from "react";
import * as Action from "../../action";
import { selectCurrentUser } from "./user.select";
import { useDispatch, useSelector } from "react-redux";
import ChangePass from "../../components/ChangePass";

const ChangePassCom = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);

  const handleFormSubmit = async (formValues, { resetForm, setSubmitting }) => {
    const newFormValues = { ...formValues, _id: currentUser._id };
    const action = await dispatch(Action.updatePassword(newFormValues));

    if (action.payload.success) {
      resetForm();
    }
    setSubmitting(false);
  };

  return (
    <ChangePass currentUser={currentUser} handleFormSubmit={handleFormSubmit} />
  );
};

export default ChangePassCom;
