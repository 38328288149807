import { createDraftSafeSelector } from "@reduxjs/toolkit";

const selectDomain = (state) => state.userReducer;

export const selectCurrentUser = createDraftSafeSelector(
  selectDomain,
  (userState) => userState.currentUser
);

export const selectisLogedin = createDraftSafeSelector(
  selectDomain,
  (userState) => userState.isLogedin
);

export const selectCurrentUserId = createDraftSafeSelector(
  selectDomain,
  (userState) => userState.currentUser._id
);

export const selectFresUser = createDraftSafeSelector(
  selectDomain,
  (userState) => userState.fresUser
);



export const selectsubscription = createDraftSafeSelector(
  selectDomain,
  (userState) => userState.subscription
);
