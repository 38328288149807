
export function setcurrentuserandtoken(userObject, token) {
    const user = JSON.stringify(userObject);
    window.localStorage.setItem("user", user);
    window.localStorage.setItem("token", token);
  }
  
  export function updatecurrentuserdata(userObject) {
    window.localStorage.removeItem("user");
    const user = JSON.stringify(userObject);
    window.localStorage.setItem("user", user);
  }
  
  export function getcurrentuser() {
    let user = window.localStorage.getItem("user");
    let toekn = window.localStorage.getItem("token");
  
    if (user && toekn) {
      user = JSON.parse(window.localStorage.getItem("user"));
      return user;
    } else {
      return false;
    }
  }
  
  export function removeuserData(userObject, token) {
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("token");
  }
  